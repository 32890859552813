import { paperTrailClientInstance } from "@services/papertrailInstance";
import React from "react";

const useFetch = (request: () => Promise<any>) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const [error, setError] = React.useState<string>("");

  const setLoading = () => setIsLoading(true);
  const unsetLoading = () => setIsLoading(false);

  const doFetch = async (message?: string) => {
    const reqBody = (globalThis as any).loggerBody;
    try {
      setLoading();
      paperTrailClientInstance.info(reqBody, message);
      const reqData = await request();
      setData(reqData);
      unsetLoading();
    } catch (e: any) {
      setError(e);
      unsetLoading();
      paperTrailClientInstance.error(reqBody, e);
    }
  };

  return { doFetch, isLoading, data, error };
};

export default useFetch;
