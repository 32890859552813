import Group from "@components/arrangement/Group/Group";
import React from "react";
import Cell from "../Cell/Cell";
import "./TableRow.scss";

interface ITableRow {
  newTransaction: boolean;
  amount: string;
  currency: string;
  date: string;
  bscScanTransactionUrl: string;
  transactionAddress: string;
  empty: boolean;
}

const TableRow = ({
  amount,
  bscScanTransactionUrl,
  currency,
  date,
  newTransaction,
  transactionAddress,
  empty
}: ITableRow) => {
  return (
    <Group className={`table-row${newTransaction ? " new" : ""} `} colGap={22}>
      <Cell>
        <p className="bold ml-2">{amount}</p>
      </Cell>
      <Cell>
        <p>{currency}</p>
      </Cell>
      <Cell>
        <p>{date}</p>
      </Cell>
      <Cell longer>
        <a
          href={bscScanTransactionUrl}
          target={"_blank"}
          className={`p1 ${!empty ? `underline primary` : ""}`}
          rel="noopener noreferrer nofollow"
        >
          {transactionAddress}
        </a>
      </Cell>
      {newTransaction && (
        <Cell>
          <p className="bold">NEW</p>
        </Cell>
      )}
    </Group>
  );
};

export default TableRow;
