import { LoggerConfig } from "src/logger/LoggerContext";

const getEnv = (path: string) => process.env[`REACT_APP_${path}`] || null;

const PAPERTRAIL_HOST_ENV = getEnv("PAPERTRAIL_HOST");
const PAPERTRAIL_PORT_ENV = getEnv("PAPERTRAIL_PORT");
const PAPERTRAIL_TOKEN_ENV = getEnv("PAPERTRAIL_TOKEN");
const PAPERTRAIL_ENABLED_ENV = getEnv("PAPERTRAIL_ENABLED");

export const getPapertrailConfig = (): LoggerConfig => {
  const config = {
    host: "",
    port: -1,
    token: "",
    enabled: false
  };

  if (!PAPERTRAIL_HOST_ENV) {
    throw new Error("Logger host env is not provided");
  } else {
    config.host = PAPERTRAIL_HOST_ENV;
  }

  if (!PAPERTRAIL_PORT_ENV) {
    throw new Error("Logger port env doesnt exist");
  } else if (!Number.isSafeInteger(Number(PAPERTRAIL_PORT_ENV))) {
    throw new Error("Logger port env has wrong value");
  } else {
    config.port = Number(PAPERTRAIL_PORT_ENV);
  }

  if (!PAPERTRAIL_TOKEN_ENV) {
    throw new Error("Logger token env is not provided");
  } else {
    config.token = PAPERTRAIL_TOKEN_ENV;
  }

  if (!PAPERTRAIL_ENABLED_ENV) {
    throw new Error("Logger enabled env is not provided");
  } else if (PAPERTRAIL_ENABLED_ENV !== "true" && PAPERTRAIL_ENABLED_ENV !== "false") {
    throw new Error("Logger enabled env has wrong value");
  } else {
    config.enabled = PAPERTRAIL_ENABLED_ENV === "true";
  }

  return config;
};

export const paperTrailConfig: LoggerConfig = getPapertrailConfig();
