import { usePromise } from "@hooks/usePromise";
import { BigNumber, ethers } from "ethers";
import moment from "moment";
import React from "react";
import { BscScanProxyClient, BscScanTx } from "src/blockchain/BscScanProxyClient";
import "./TxListAdmin.scss";
import { inspect } from "util";
import { ethereum } from "@services/ethereumInstance";
import { blockchainConfig } from "@config/config";

export interface TxListAdminProps {
  bscScan: BscScanProxyClient;
}

const calculateTotalValue = (txes: BscScanTx[]) => {
  return txes.reduce((acc, tx) => acc.add(tx.value), BigNumber.from(0));
};

const TxListAdmin = ({ bscScan }: TxListAdminProps) => {
  const [access, setAccess] = React.useState("");
  const { resolved, call, waiting, rejected } = usePromise((access) =>
    bscScan.getTransactionsForAccessCode(ethereum.base58CodeToAddress(access) ?? "")
  );

  return (
    <div className="tx-list-admin">
      <label style={{ display: "block" }}>Access code</label>
      <input
        style={{ margin: 0, marginBottom: 20, width: 500 }}
        value={access}
        onChange={(e) => setAccess(e.target.value)}
      />
      <button onClick={() => call(access)} style={{ color: "lightgreen", width: 100 }}>
        Search
      </button>
      <div style={{ marginBottom: 20 }}>Transaction number: {resolved?.length ?? 0}</div>
      <div style={{ marginBottom: 20 }}>
        Total value: {resolved ? ethers.utils.formatEther(calculateTotalValue(resolved)) : "0"}
      </div>
      <table>
        <thead>
          <tr>
            <th>Index</th>
            <th>From</th>
            <th>Timestamp</th>
            <th>Value (USD)</th>
            <th>Tx</th>
          </tr>
        </thead>
        <tbody>
          {(resolved ?? []).map((item, index) => (
            <tr key={item.txHash}>
              <td>{index + 1}</td>
              <td>
                <a style={{ color: "lightblue" }} href={`${blockchainConfig.BSC.scannerUrl}/address/${item.from}`}>
                  {item.from}
                </a>
              </td>
              <td>{moment(item.timestamp * 1000).format("lll")}</td>
              <td>{ethers.utils.formatEther(item.value)}</td>
              <td>
                <a style={{ color: "lightblue" }} href={item.url}>
                  ...{item.txHash.slice(-10)}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {waiting && "Loading..."}
      {rejected && inspect(rejected)}
    </div>
  );
};

export default TxListAdmin;
