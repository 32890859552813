import React from "react";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import "./TextArea.scss";

interface ITextArea {
  label?: string;
  value: string;
  onChange(value: string): void;
  error?: string;
  placeholder?: string;
  darkBackground?: boolean;
  className?: string;
}

const TextArea = ({
  label = "",
  onChange,
  value,
  error = "",
  placeholder = "",
  darkBackground = false,
  className = ""
}: ITextArea) => {
  const id = React.useId();
  const textareaClass = `${error && "danger-border danger"} ${darkBackground && "dark-background"}`;

  return (
    <Stack className={`textarea ${className}`} justifyContent="flex-start">
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        id={id}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        className={textareaClass}
      />
      {error && (
        <Group colGap={4.5} alignItems="center" className="error">
          <img src="/images/error-x.svg" alt="error icon" />
          <p>{error}</p>
        </Group>
      )}
    </Stack>
  );
};

export default TextArea;
