import Group from "@components/arrangement/Group/Group";
import React from "react";
import "./Cell.scss";

interface ICell {
  center?: boolean;
  children: React.ReactNode;
  longer?: boolean;
}

const Cell = ({ center = false, children, longer }: ICell) => {
  return (
    <Group
      justifyContent={center ? "center" : "flex-start"}
      alignItems="center"
      className={`cell${longer ? " longer" : ""}`}
    >
      {children}
    </Group>
  );
};

export default Cell;
