import React from "react";
import { useMetaMask } from "metamask-react";

import GoldenCardContainer from "./GoldenCardContainer/GoldenCardContainer";
import OrderHistory from "./OrderHistory/OrderHistory";
import "./TransactionsHistory.scss";
import { blockchainConfig } from "@config/config";

const TransactionsHistory = () => {
  const { status: metamaskStatus, account, chainId } = useMetaMask();
  const connected = metamaskStatus === "connected";

  const transactionHistoryAppear = connected && !!account && chainId === blockchainConfig.BSC.chainId;

  return (
    <>
      {transactionHistoryAppear && (
        <div className="transactions-history" id="transactions">
          <div className="xs-5">
            <GoldenCardContainer />
          </div>
          <div className="xs-7 mt-6">
            <OrderHistory />
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionsHistory;
