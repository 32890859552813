import React from "react";
import SalesStages from "@sharedComponents/SalesStages/SalesStages";
import { salesStages } from "@data/salesStages";
import "./Timeline.scss";

const Timeline = () => {
  return (
    <div className="timeline">
      <h5 className="white title mb-9">Timeline</h5>
      <SalesStages salesStages={salesStages} />
    </div>
  );
};

export default Timeline;
