import { BigNumberish, ethers } from "ethers";
import React from "react";
import { Navigate } from "react-router-dom";
import { BscScanProxyClient } from "src/blockchain/BscScanProxyClient";
import { Ethereum } from "src/blockchain/Ethereum";
import { paths } from "@data/paths";
import { useWalletData } from "@contexts/WalletDataContext";
import { useMetaMask } from "metamask-react";
import "./DebugPage.scss";

const ethereum = new Ethereum({
  rpcUrl: "https://bsc-dataseed1.binance.org",
  privateSaleAddress: "0x2DB251Fa4B7E165C24156623d59e54dBC9f01073",
  usdAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  tokenAddress: "0x6b63EF561B40e296d97A3CA62f71233876AfdD96",
  knownWhitelistedAddresses: []
});

const bscScan = new BscScanProxyClient({
  url: "https://fontend-utils.skey.dev/bsc-scan/latest"
});

//DEV
// | Base58 code              | Address                                      |
// | ------------------------ | -------------------------------------------- |
// | `EfNjBMDaj4J7JsJ3GD9rfa` | `0xd77d477ccEf435A0C8E22989001279075916bDEe` |
// | `28cF4du5LqxZw13VKPWQgk` | `0x4d3Bfc4ffC0119643f358dafd92Bb16B2392b909` |
// | `g2hvVjTeGE6R8kGeGS317`  | `0xeB8Ab17a1825CF6aAAB3f8CC98E835380DE9bC32` |
// | `Pky2MJTavmPjBZYC4mojPn` | `0x2D7d57f822B9B77D39f3F90D20e178a9603cBfCd` |
// | `2xk7usuVUxQF7ti1NZWrYt` | `0x2E209246ce2F77165Aef41d6f2672a4774CBaEcA` |
// | `2xfUD9ipzzHMUrguYN7qPW` | `0x5b85687c52a1B27f80B387b9D02c8a0b2E4Ba442` |
// | `H8CrtLx6zAP2ahzpzZzdkS` | `0x8e226593c9dF56e974c7061E8Ec8798c8640d655` |
// | `CSKusiweopbB6Rv4Uij5Bi` | `0xdb5bc05676681562A744dce9E9A78544ceD74550` |
// | `P65DSdwFfSYNM1mzJHDRxF` | `0xf950BA6f9584d42D84fEF22eaa0F30b07B01163b` |
// | `Hw7ECuSCx5jfCvGqwWg3CQ` | `0xb44811bCBacd58Ae3EC28215dE3f90083BE95a1d` |

//MAIN
// UizEWScB5d7oRN9tdP3CpM 0x741dBF0958E2baADCdF721a2286565EE0Fec836D
// TjCJXkfeLwiV2STvM8MvA2 0xa52308601D3c62F9490df976c86A90dE938EF5c7
// JASfkKniw7x4hNCawgUs7a 0x4ff606C678986D2F7e3d7424d4fBE2de3eeAfA6e
// 4iacQAXV4oXx9dugj6fsKF 0x9b4679fA537cb7A652Cd67C775388e34B5Aa7C6a
// FZMPo86it6BTaRRcAS2VEZ 0x2F7842EC67aa1aA5764C7677200f83f6b95F14f2
// 9ahYFi6EaKkRpYXP749UXj 0x5b92407967f6729aeAFA048ae72354F0c2A3CA09
// By9vCsUWEcn47i4FXj2yvj 0x8F4c2D6350A6fA9D2dA3F6539a5F0DC8c131eB64
// K5eUMNiVapwcE77hp8tRtL 0x460CBfd601AF8F8E6Bcf1AB54D93893cEbf73811
// UNkg9Rb5GA614rbtB4xvbQ 0x0451Aa1451e5FF07e054d55Ecd736e96438E66D3
// Mk8SL4ExTFySiQptFtb7u7 0x3D7F8C29AAC1aa5Fd28C1D1D7E0D1B40684e94b4

const win = window as any;

win.matchError = (id: string) => ethereum.matchError(ethereum.privateSaleInstance, id);
win.calculateFeeCost = (busdValue: BigNumberish) => ethereum.calculateFeeCost(busdValue);

const DebugPage = () => {
  const [code, setCode] = React.useState("UizEWScB5d7oRN9tdP3CpM");
  const [usdBalance, setUsdBalance] = React.useState(ethers.utils.parseEther("1010101"));
  const [allowance, setAllowance] = React.useState(ethers.utils.parseEther("1010101"));
  const [etherBalance, setEtherBalance] = React.useState(ethers.utils.parseEther("1010101"));
  const [codeValid, setCodeValid] = React.useState(false);
  const [buyValue, setBuyValue] = React.useState("10000");

  const metamask = useMetaMask();
  const walletData = useWalletData();

  if (process.env.NODE_ENV === "production") return <Navigate to={paths.accessPage} />;

  return (
    <div className="debug-page">
      <img src="https://i.giphy.com/media/3o84sw9CmwYpAnRRni/giphy.webp" alt="doit" />
      <table className="actions">
        <tbody>
          <tr>
            <td>Connect</td>
            <td>
              <button onClick={() => metamask.connect()}>Do</button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Ether Balance</td>
            <td>
              <button onClick={() => ethereum.getEtherBalance().then(setEtherBalance)}>Get</button>
            </td>
            <td>{ethers.utils.formatEther(etherBalance)}</td>
          </tr>
          <tr>
            <td>Usd Balance</td>
            <td>
              <button onClick={() => ethereum.getUsdBalance().then(setUsdBalance)}>Get</button>
            </td>
            <td>{ethers.utils.formatEther(usdBalance)}</td>
          </tr>
          <tr>
            <td>Allowance</td>
            <td>
              <button onClick={() => ethereum.getUsdAllowance().then(setAllowance)}>Get</button>
            </td>
            <td>{ethers.utils.formatEther(allowance)}</td>
          </tr>
          <tr>
            <td>Validate base58 code</td>
            <td>
              <input value={code} onChange={(e) => setCode(e.target.value)} />
              <button onClick={() => ethereum.isAccessCodeValid(code).then(setCodeValid)}>Check</button>
            </td>
            <td>{codeValid ? "Yeah" : "Nah"}</td>
          </tr>
          <tr>
            <td>Buy</td>
            <td>
              <input value={buyValue} onChange={(e) => setBuyValue(e.target.value)} />
              <button onClick={() => ethereum.handleBuyOrder(ethers.utils.parseEther(buyValue), code, console.log)}>
                Buy
              </button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Claim</td>
            <td>
              <button
                onClick={() =>
                  ethereum
                    .handleClaimOrder(console.log)
                    .then(() => alert("Done"))
                    .catch(() => alert("Failed"))
                }
              >
                Claim
              </button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Claim Bonus</td>
            <td>
              <button
                onClick={() =>
                  ethereum
                    .handleClaimBonusOrder(console.log)
                    .then(() => alert("Done"))
                    .catch(() => alert("Failed"))
                }
              >
                Claim Bonus
              </button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>User data</td>
            <td>
              <button onClick={() => ethereum.getUserData().then(console.log)}>Get</button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Sale data</td>
            <td>
              <button onClick={() => ethereum.getSaleData().then(console.log)}>Get</button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Get user transactions</td>
            <td>
              <button
                onClick={async () => {
                  const [address] = await (window as any).ethereum.enable();
                  await bscScan.getTransactionsForUser(address).then(console.log);
                }}
              >
                Get
              </button>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Refetch wallet data</td>
            <td>
              <button
                onClick={async () => {
                  walletData.refetch();
                }}
              >
                Refetch
              </button>
            </td>
            <td style={{ maxWidth: 300, wordBreak: "break-word" }}>{JSON.stringify(walletData)}</td>
          </tr>
        </tbody>
      </table>
      <img
        src="https://media1.giphy.com/media/xTiIzL9Btjx9hegHT2/giphy.gif?cid=ecf05e476gfles0web3ubx4bkhkr742nmi2x2r5776yke6a4&rid=giphy.gif&ct=g"
        alt="good"
        style={{ marginTop: 75 }}
      />
    </div>
  );
};

export default DebugPage;
