import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Dots from "@sharedComponents/Dots/Dots";
import { useOrderHistory } from "@contexts/OrderHistoryContext";
import moment from "moment";
import TableRow from "../TableRow/TableRow";
import "./TableBody.scss";

const MAX_ROWS = 10;

const TableBody = () => {
  const { status: orderHistoryStatus, orders, firstAsNew } = useOrderHistory();

  const EmptyState = () => {
    return (
      <TableRow
        newTransaction={false}
        amount={"0"}
        currency={"BUSD"}
        date={"-"}
        bscScanTransactionUrl={"-"}
        transactionAddress={"-"}
        empty={true}
      />
    );
  };

  const Content = () => {
    if (orderHistoryStatus === "loading") {
      return (
        <p className="mt-1 white relative">
          Order history is loading
          <Dots />
        </p>
      );
    }
    if (orderHistoryStatus === "failed") {
      return <p className="mt-1 white">Failed to load order history</p>;
    }
    if (orders.length === 0) {
      return <EmptyState />;
    }

    return <></>;
  };

  return (
    <Stack className="table-body" rowGap={13}>
      {<Content />}
      <>
        {orders.length !== 0 &&
          orders
            .slice(0, MAX_ROWS)
            .map((order, index) => (
              <TableRow
                key={order.txHash}
                newTransaction={index === 0 && firstAsNew}
                amount={Number(order.value.div(10n ** 18n)).toLocaleString()}
                currency="BUSD"
                date={moment.unix(order.timestamp).format("DD.MM.YYYY")}
                bscScanTransactionUrl={order.url}
                transactionAddress={order.txHash}
                empty={false}
              />
            ))}
      </>
    </Stack>
  );
};

export default TableBody;
