import React from "react";
import { useMetaMask } from "metamask-react";
import WalletAddress from "./WalletAddress/WalletAddress";

const WalletAddressContainer = () => {
  const { account } = useMetaMask();
  return <WalletAddress address={account} />;
};

export default WalletAddressContainer;
