import React from "react";
import { useWalletData } from "@contexts/WalletDataContext";
import WalletBalance from "./WalletBalanace/WalletBalance";
import { BigNumber, ethers } from "ethers";
import { MIN_BNB_BALANCE } from "@data/saleData";
import { useContractData } from "@contexts/ContractDataContext";

export type BalanceState =
  | "too-low-bnb"
  | "too-low-busd"
  | "too-low-both"
  | "default"
  | "loading"
  | "failed-contract"
  | "failed-wallet";

const WalletBalanceContainer = () => {
  const { status: walletStatus, balances } = useWalletData();
  const { busdBalance, bnbBalance } = balances;

  const contractData = useContractData();
  const contractSuccess = contractData.status === "success";
  const minValue = contractSuccess ? Math.min(...contractData.saleData.map((dataObject) => dataObject.busdCost)) : 0;

  const balanceStates = (): BalanceState => {
    if (walletStatus === "loading" || contractData.status === "loading") {
      return "loading";
    }
    if (walletStatus === "failed") {
      return "failed-wallet";
    }
    if (contractData.status === "failed") {
      return "failed-contract";
    }
    if (
      busdBalance.lt(ethers.utils.parseEther(minValue.toString())) &&
      bnbBalance.lt(ethers.utils.parseEther(MIN_BNB_BALANCE))
    ) {
      return "too-low-both";
    }
    if (busdBalance.lt(ethers.utils.parseEther(minValue.toString()))) {
      return "too-low-busd";
    }
    if (bnbBalance.lt(ethers.utils.parseEther(MIN_BNB_BALANCE))) {
      return "too-low-bnb";
    }

    return "default";
  };

  const balanceState = balanceStates();

  const bigNumberToStringWithFixed = (value: BigNumber, decimals: number, decimalPrecision: number) => {
    const integers = value.div(10n ** BigInt(decimals));

    const b = value.mod(10n ** BigInt(decimals));
    const bLength = b.toString().length;
    const bLengthDiff = decimals - bLength;
    let zerosToAdd = "";

    if (bLengthDiff > 0) {
      for (let index = 0; index < bLengthDiff; index++) {
        zerosToAdd += "0";
      }
    }

    const decimalsToDisplay = b.div(10n ** BigInt(decimals - decimalPrecision));
    return Number(`${integers}.${zerosToAdd}${decimalsToDisplay}`).toLocaleString();
  };

  const fixedBusd = bigNumberToStringWithFixed(busdBalance, 18, 2);
  const fixedBnb = bigNumberToStringWithFixed(bnbBalance, 18, 4);

  const getError = () => {
    if (balanceState === "default" || balanceState === "loading") {
      return null;
    }
    if (balanceState === "failed-contract") {
      return <p className="p2">Couldn't get contract data</p>;
    }
    if (balanceState === "failed-wallet") {
      return <p className="p2">Couldn't get wallet balances</p>;
    }
    if (balanceState === "too-low-both") {
      return (
        <p className="p2">
          <b>{fixedBusd} BUSD </b>
          is too low to make any transaction. Also, you need at least <b>{MIN_BNB_BALANCE} BNB </b> to pay the
          transactions fee.
        </p>
      );
    }
    if (balanceState === "too-low-bnb") {
      <p className="p2">
        <b>{fixedBnb} BNB</b> is too low to pay transactions fee.
      </p>;
    }
    if (balanceState === "too-low-busd") {
      return (
        <p className="p2">
          <b>{fixedBusd} BUSD</b> is too low to make any transaction
        </p>
      );
    }
  };
  const error = getError();

  return <WalletBalance balanceState={balanceState} error={error} busdBalance={fixedBusd} />;
};

export default WalletBalanceContainer;
