import React from "react";
import Group from "@components/arrangement/Group/Group";
import { useMetaMask } from "metamask-react";
import { blockchainConfig } from "@config/config";
import "./CurrentNetwork.scss";

const validChainId = blockchainConfig.BSC.chainId;
const validNetworkName = blockchainConfig.BSC.name;

const Network = () => {
  const { chainId } = useMetaMask();

  return (
    <Group className="network" alignItems="center" colGap={8}>
      <img src="/images/BinanceLogo.svg" alt={"binance logo"} />
      <p className=" contentBinance white-4">
        {chainId === validChainId ? validNetworkName : `Switch to ${validNetworkName}`}
      </p>
    </Group>
  );
};

export default Network;
