import React from "react";
import { useMetaMask } from "metamask-react";
import { useModalsState } from "@contexts/ModalsStateContext";
import { blockchainConfig } from "@config/config";

interface IContext {
  children: React.ReactNode;
}

const EffectsContext = React.createContext(null as any);

export const EffectsProvider = ({ children }: IContext) => {
  const { status, chainId } = useMetaMask();
  const { closeConnectMetamask, closeSwitchNetwork, stopSwitchingNetwork } = useModalsState();

  React.useEffect(() => {
    if (status === "connected") {
      closeConnectMetamask();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // React.useEffect(() => {
  //   if (status === "connected" && chainId !== blockchainConfig.BSC.chainId) {
  //     openSwitchNetwork();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chainId, status]);

  React.useEffect(() => {
    if (chainId === blockchainConfig.BSC.chainId) {
      closeSwitchNetwork();
      stopSwitchingNetwork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return <EffectsContext.Provider value={""}>{children}</EffectsContext.Provider>;
};

export const useEffectsContext = () => React.useContext(EffectsContext);
