import "./WalletAddress.scss";
import { shortenAddress } from "../../../../utils/shortenAddress";
import Group from "@components/arrangement/Group/Group";

interface WalletAddressInterface {
  address: string | null;
}

const WalletAddress = ({ address }: WalletAddressInterface) => {
  return (
    <Group className="walletAddress" alignItems="center" colGap={12}>
      <p className="contentWallet white-4">{address ? shortenAddress(address) : "No wallet connected"}</p>
      <img
        src={address ? "/images/CircleActive.svg" : "/images/CircleDisable.svg"}
        className={"circleImages"}
        alt={"status circle"}
      />
    </Group>
  );
};

export default WalletAddress;
