import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import { useMetaMask } from "metamask-react";
import { blockchainConfig } from "@config/config";
import { currentNetworkParams } from "../../../../blockchain/metamask/currentNetworkParams";
import Group from "@components/arrangement/Group/Group";
import Motion from "@components/ux/Motion/Motion";
import { paperTrailClientInstance } from "@services/papertrailInstance";
import { useModalsState } from "@contexts/ModalsStateContext";
import Spinner from "@components/ux/Spinner/Spinner";
import "./SwitchNetworkModal.scss";

const DOESNT_HAVE_NETWORK_ADDED_CODE = 4902;
const REJECTED_ADDING_NETWORK_CODE = 4001;

const SwitchNetworkModal = () => {
  const { switchChain, addChain } = useMetaMask();
  const networkName = blockchainConfig.BSC.name;
  const [ping, setPing] = React.useState(false);
  const [showAddNetwork, setShowAddNetwork] = React.useState(false);
  const { switchingNetwork, startSwitchingNetwork, stopSwitchingNetwork, closeSwitchNetwork } = useModalsState();

  const startPing = () => setPing(true);
  const stopPing = () => setPing(false);

  const handleAddChain = async () => {
    stopPing();
    const reqBody = (globalThis as any).loggerBody;
    try {
      await addChain(currentNetworkParams);
    } catch (e: any) {
      if (e.code === REJECTED_ADDING_NETWORK_CODE) {
        paperTrailClientInstance.error(reqBody, `User rejected to add network`);
        startPing();
      } else {
        paperTrailClientInstance.error(reqBody, `${e}`);
      }
    }
  };

  const abortSwitchingChain = () => {
    stopSwitchingNetwork();
    closeSwitchNetwork();
  };

  const handleSwitchChain = async () => {
    try {
      startSwitchingNetwork();
      await switchChain(blockchainConfig.BSC.chainId);
    } catch (e: any) {
      if (e.code === DOESNT_HAVE_NETWORK_ADDED_CODE) {
        startPing();
        setShowAddNetwork(true);
        stopSwitchingNetwork();
        return;
      }
      abortSwitchingChain();
    }
  };

  return (
    <Backdrop open onClick={abortSwitchingChain}>
      <Portal>
        <Stack justifyContent="center" alignItems="center">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="switch-network-modal" alignItems="center">
                <button onClick={abortSwitchingChain} className="close-icon">
                  <img src="/images/close-icon.svg" alt="close icon" />
                </button>
                <img src="/images/binance-logo.svg" alt="binance logo" className="mb-2 logo" width={81} height={81} />
                <p className="network-name white mb-1">{networkName}</p>
                <h6 className="title white mb-6">Switch network to {networkName}</h6>
                <Button onClick={handleSwitchChain} variant="primary">
                  <Group colGap={16}>
                    Switch network
                    {switchingNetwork && <Spinner variant="black" />}
                  </Group>
                </Button>
                {showAddNetwork && (
                  <Motion appear={true} duration={3} className="mt-2">
                    <Group colGap={4} alignItems="flex-start">
                      <p className="p2 white-2">Don't have {networkName} added yet?</p>
                      <Motion duration={5} pulse={ping}>
                        <span role="button" onClick={handleAddChain} className="primary clickable p2">
                          Add network
                        </span>
                      </Motion>
                    </Group>
                  </Motion>
                )}
                {/* <p className="white-2 p2 additional mt-7">
                  By connecting a wallet, you agree to Go2NFT
                  <a href={TERMS_OF_SERVICE} download className="link">
                    Terms of Service
                  </a>
                  and acknowledge that you have read and understand the Go2NFT
                </p> */}
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default SwitchNetworkModal;
