import React from "react";
import Group from "@components/arrangement/Group/Group";
import Network from "./CurrentNetwork/CurrentNetwork";
import WalletAddressContainer from "./WalletAddressContainer/WalletAddressContainer";
import WalletBalanceContainer from "./WalletBalanceContainer/WalletBalanceContainer";
import { useMetaMask } from "metamask-react";
import ConnectMetamask from "./ConnectMetamask/ConnectMetamask";
import { blockchainConfig } from "@config/config";
import SwitchNetwork from "./SwitchNetwork/SwitchNetwork";
import "./InnerNav.scss";

const InnerNav = () => {
  const reload = () => {
    window.location.reload();
  };

  const { status, chainId } = useMetaMask();
  const navbarContent = () => {
    if (status !== "connected") {
      return <ConnectMetamask />;
    }
    if (chainId !== blockchainConfig.BSC.chainId) {
      return <SwitchNetwork />;
    }
    return (
      <>
        <Network />
        <WalletBalanceContainer />
        <WalletAddressContainer />
      </>
    );
  };

  return (
    <Group justifyContent="space-between" className="group-inner-nav">
      <button onClick={reload}>
        <img src="/images/go2nft-navigation-logo.svg" alt="go2nft logo" />
      </button>
      <Group colGap={24} justifyContent={"center"}>
        {navbarContent()}
      </Group>
    </Group>
  );
};

export default InnerNav;
