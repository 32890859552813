import {
  CURRENT_PRIVATE_SALE_ROUND,
  MAX_PRIVATE_SALE_ROUNDS,
  CURRENT_VESTING_ROUND,
  MAX_VESTING_ROUNDS,
  CURRENT_ICO_ROUND,
  MAX_ICO_ROUNDS
} from "@config/config";
import { SaleStageType } from "@sharedComponents/SalesStages/SaleStage/SaleStage";

export const salesStages: SaleStageType[] = [
  {
    title: "Private sale",
    subtitle: "Depositing BUSD into the contract, the tokens are locked.",
    description: "It will be divided into 3 phases, the earlier you buy - the cheaper the cost.",
    currentRound: CURRENT_PRIVATE_SALE_ROUND,
    maxRound: MAX_PRIVATE_SALE_ROUNDS
  },
  {
    title: "Vesting",
    subtitle: "Starts from October 2023 to July 2024",
    description: "There will be 10 vesting rounds, each lasting 20 days.",
    currentRound: CURRENT_VESTING_ROUND,
    maxRound: MAX_VESTING_ROUNDS
  },
  {
    title: "ICO",
    subtitle: "Public Sale",
    description: "More information coming soon.",
    currentRound: CURRENT_ICO_ROUND,
    maxRound: MAX_ICO_ROUNDS
  }
];
