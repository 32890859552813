import React from "react";
import FooterActions from "./FooterActions/FooterActions";
import "./PresaleUtils.scss";

const PresaleUtils = () => {
  return (
    <div className="presale-utils">
      <hr className="white-2" />
      <FooterActions />
    </div>
  );
};

export default PresaleUtils;
