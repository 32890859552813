import React from "react";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Spinner from "@components/ux/Spinner/Spinner";
import "./Step.scss";

interface StepProps {
  active: boolean;
  loading: boolean;
  stepNumber: number;
  title: string;
  description: string;
}

const Step = ({ active, description, loading, stepNumber, title }: StepProps) => {
  return (
    <Stack className={`transaction-step${active || loading ? " active" : ""}`} alignItems="center">
      <Group justifyContent="center" className="step-number mb-2 p0">
        {loading ? <Spinner variant="black" className="max-content" /> : stepNumber}
      </Group>
      <h6 className="step-title white mb-2">{title}</h6>
      <p className="p2 white-2 description">{description}</p>
    </Stack>
  );
};

export default Step;
