import React from "react";
import Group from "@components/arrangement/Group/Group";
import { blockchainConfig } from "@config/config";
import addTokenToMetamask, { IToken } from "src/blockchain/metamask/addTokenToMetamask";
import FooterAction from "./FooterAction";
import "./FooterActions.scss";

const getPrivateSaleUrl = () => {
  const { BSC, PRIVATE_SALE } = blockchainConfig;
  return `${BSC.scannerUrl}/address/${PRIVATE_SALE.address}`;
};

const g2nToken: IToken = {
  address: blockchainConfig.G2N_TOKEN.address,
  symbol: "G2N",
  decimals: blockchainConfig.G2N_TOKEN.decimals,
  image: `${window.location.origin}/images/G2N_Metamask_256x256.png`
};

const busdToken: IToken = {
  address: blockchainConfig.USD_TOKEN.address,
  symbol: "BUSD",
  decimals: blockchainConfig.USD_TOKEN.decimals,
  image: `${window.location.origin}/images/BUSD_Metamask_256x256.png`
};

const FooterActions = () => {
  return (
    <Group colGap={32} className="footer-actions" alignItems="flex-start">
      <FooterAction
        img="/images/footer-logo-1.svg"
        title="Add G2N Token"
        description="Add Go2NFT Token address to MetaMask. Make sure your Go2NFT tokens are visible in the list of assets."
        buttonLabel="Add g2n token"
        action={addTokenToMetamask(g2nToken)}
      />
      <FooterAction
        img="/images/footer-logo-2.svg"
        title="Private Sale contract address"
        description="See transactions executed on this contract"
        buttonLabel="Show contract"
        action={() => window.open(getPrivateSaleUrl(), "_blank")}
      />
      <FooterAction
        img="/images/footer-logo-3.svg"
        title="Add BUSD Token"
        description="Add BUSD Token address to MetaMask. Make sure your BUSD tokens are visible in the list of assets."
        buttonLabel="Add BUSD Token"
        action={addTokenToMetamask(busdToken)}
      />
    </Group>
  );
};

export default FooterActions;
