import React from "react";
import useValidateAccessCode from "@hooks/useValidateAccessCode";

interface IContext {
  children: React.ReactNode;
}

const AccessCodeContext = React.createContext(null as any);

export const AccessCodeProvider = ({ children }: IContext) => {
  const [accessCode, setAccessCode] = React.useState("");
  const { verifyingAccessCode, accessCodeValid, validateAccessCode } = useValidateAccessCode(accessCode);

  const handleSetAccessCode = (accessCode: string) => setAccessCode(accessCode.trim());
  const resetAccessCode = () => setAccessCode("");

  return (
    <AccessCodeContext.Provider
      value={{
        accessCode,
        verifyingAccessCode,
        validateAccessCode,
        accessCodeValid,
        resetAccessCode,
        handleSetAccessCode
      }}
    >
      {children}
    </AccessCodeContext.Provider>
  );
};

export const useAccessCodeContext = () => React.useContext(AccessCodeContext);
