import { ethers, Wallet, BigNumber, BaseContract, PayableOverrides, BigNumberish } from "ethers";
import { PrivateSale__factory } from "./typechain-types/factories/PrivateSale__factory";
import { Buffer } from "buffer";
import { Token__factory } from "./typechain-types/factories/Token__factory";
import { paperTrailClientInstance } from "@services/papertrailInstance";

export interface EthereumOptions {
  rpcUrl: string;
  privateSaleAddress: string;
  usdAddress: string;
  tokenAddress: string;
  knownWhitelistedAddresses?: string[];
}

export interface ECDSASignResult {
  sig: string;
  hash: string;
  message: Buffer;
}

export interface EthereumEvent {
  name: string;
  data: Record<string, string>;
}

export type EventCallback = (e: EthereumEvent) => void;

export class Ethereum {
  private jsonRpcProvider: ethers.providers.StaticJsonRpcProvider;
  private knownWhitelistedAddresses: string[];

  constructor(private opts: EthereumOptions) {
    this.jsonRpcProvider = new ethers.providers.StaticJsonRpcProvider(opts.rpcUrl);

    this.knownWhitelistedAddresses = (this.opts.knownWhitelistedAddresses ?? []).map((addr) => addr.toLowerCase());
  }

  async isAccessCodeValid(accessCode: string) {
    if (!this.validateBase58Code(accessCode)) return false;

    const { address } = this.base58CodeToWallet(accessCode);

    if (this.knownWhitelistedAddresses.includes(address.toLowerCase())) {
      return true;
    }

    return await this.privateSaleInstance.isWhitelisted(address);
  }

  async getCardsData() {
    return await this.privateSaleInstance.getBuyData();
  }

  async getUserData() {
    const signer = this.web3Provider.getSigner();
    const address = await signer.getAddress();

    return await this.privateSaleInstance.users(address);
  }

  async getSaleData() {
    return await this.privateSaleInstance.saleData();
  }

  async handleBuyOrder(busdToSpent: BigNumber, accessCode: string, onEvent?: EventCallback) {
    if (!(await this.isAccessCodeValid(accessCode))) {
      throw new Error("Invalid base58 code");
    }

    await this.assertAllowance(busdToSpent, onEvent);
    await this.buy(busdToSpent, accessCode, onEvent);
  }

  async handleClaimOrder(onEvent?: EventCallback) {
    await this.callMethod(this.privateSaleInstance, "claim", [], {}, onEvent);
  }

  async handleClaimBonusOrder(onEvent?: EventCallback) {
    await this.callMethod(this.privateSaleInstance, "claimBonus", [], {}, onEvent);
  }

  async getEtherBalance() {
    const signer = this.web3Provider.getSigner();

    return await signer.getBalance();
  }

  async getUsdBalance() {
    const signer = this.web3Provider.getSigner();
    const address = await signer.getAddress();

    return await this.usdInstance.balanceOf(address);
  }

  async getUsdAllowance() {
    const signer = this.web3Provider.getSigner();
    const address = await signer.getAddress();

    return await this.usdInstance.allowance(address, this.opts.privateSaleAddress);
  }

  base58CodeToAddress(accessCode: string) {
    try {
      return this.base58CodeToWallet(accessCode).address;
    } catch {
      console.error("Invalid base58 code");
      return null;
    }
  }

  matchError<T extends BaseContract>(contract: T, errorId: string) {
    const errors = Object.values(contract.interface.errors).map((err) => ({
      ...err,
      id: ethers.utils.id(err.format()).slice(0, 10)
    }));

    const error = errors.find((err) => err.id === errorId);
    if (!error) return null;

    return error.name;
  }

  public get privateSaleInstance() {
    return PrivateSale__factory.connect(this.opts.privateSaleAddress, this.jsonRpcProvider);
  }

  async calculateFeeCost(busdValue: BigNumberish) {
    const ESTIMATED_INCREASE_ALLOWANCE_GAS = BigNumber.from(52000);
    const ESTIMATED_BUY_GAS = BigNumber.from(140000);

    const sender = await this.web3Provider.getSigner().getAddress();
    const value = BigNumber.from(busdValue);

    const [feeData, currentAllowance] = await Promise.all([
      this.jsonRpcProvider.getFeeData(),
      this.usdInstance.allowance(sender, this.opts.privateSaleAddress)
    ]);

    if (!feeData.gasPrice) throw new Error("Incompatible tx type");

    const gasPrice = feeData.gasPrice;

    const allowanceFee = value.gt(currentAllowance)
      ? ESTIMATED_INCREASE_ALLOWANCE_GAS.mul(gasPrice)
      : BigNumber.from(0);

    const buyFee = ESTIMATED_BUY_GAS.mul(gasPrice);

    const totalFee = allowanceFee.add(buyFee);

    return { allowanceFee, buyFee, totalFee };
  }

  private async callMethod<T extends BaseContract>(
    contract: T,
    func: string,
    args: any[],
    overrides?: PayableOverrides,
    onEvent?: EventCallback
  ) {
    const signer = this.web3Provider.getSigner();

    this.report(onEvent, `${func}Tx`);

    const tx = await contract.connect(signer)[func](...args, overrides);

    this.report(onEvent, `${func}TxSent`, { txHash: tx.hash });

    const receipt = await tx.wait();

    this.report(onEvent, `${func}TxConfirmed`, { receipt, args });
  }

  private async assertAllowance(busdToSpent: BigNumber, onEvent?: EventCallback) {
    const signer = this.web3Provider.getSigner();
    const address = await signer.getAddress();

    const currentAllowance = await this.usdInstance.allowance(address, this.opts.privateSaleAddress);

    this.report(onEvent, "currentAllowance", { currentAllowance });

    if (currentAllowance.gte(busdToSpent)) return;

    await this.callMethod(
      this.usdInstance,
      "increaseAllowance",
      [this.opts.privateSaleAddress, busdToSpent.sub(currentAllowance)],
      {},
      onEvent
    );
  }

  private async buy(busdToSpent: BigNumber, accessCode: string, onEvent?: EventCallback) {
    const signer = this.web3Provider.getSigner();
    const address = await signer.getAddress();

    const { sig, hash } = await this.ecdsaSignAddress(this.base58CodeToWallet(accessCode), address);

    this.report(onEvent, "ecdsaSign", { sig, hash });

    await this.callMethod(this.privateSaleInstance, "buy", [hash, sig, busdToSpent], {}, onEvent);
  }

  private async ecdsaSignAddress(signer: Wallet, address: string): Promise<ECDSASignResult> {
    const sig = await signer.signMessage(address.toLowerCase());

    const text = `\x19Ethereum Signed Message:\n42${address.toLowerCase()}`;
    const message = Buffer.from(text, "utf8");
    const hash = ethers.utils.keccak256(message);

    return { sig, hash, message };
  }

  private base58CodeToWallet(accessCode: string) {
    const half = Buffer.from(ethers.utils.base58.decode(accessCode));
    return new Wallet(Buffer.concat([half, half]));
  }

  private get web3Provider() {
    return new ethers.providers.Web3Provider((window as any).ethereum);
  }

  private get usdInstance() {
    return Token__factory.connect(this.opts.usdAddress, this.jsonRpcProvider);
  }

  private validateBase58Code(accessCode: string) {
    const regex = /^[A-HJ-NP-Za-km-z1-9]*$/;
    if (!regex.test(accessCode)) return false;

    const bytes = ethers.utils.base58.decode(accessCode);
    if (bytes.length !== 16) return false;

    return true;
  }

  private report(cb: EventCallback | undefined, eventName: string, eventData?: Record<string, any>) {
    const eventDataAsString = JSON.stringify({ ...eventData, receipt: { ...eventData?.receipt, logs: [] } });
    const reqBody = (globalThis as any).loggerBody;
    paperTrailClientInstance.info(reqBody, `BuyingProcess: eventName: ${eventName}, eventData: ${eventDataAsString}`);
    cb?.({ name: eventName, data: eventData ?? {} });
  }
}
