import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import { useMetaMask } from "metamask-react";
import { appStage, blockchainConfig } from "@config/config";
import { useTransactionProcess } from "@contexts/TransactionProcessContext";
import { useWalletData } from "@contexts/WalletDataContext";
import { paperTrailClientInstance } from "@services/papertrailInstance";
import { getRecordSessionStorage, SESSION_STORAGE_ACCESS_CODE } from "src/utils/sessionStorage/sessionStorage";

export type Enabled = "true" | "false";

export interface LoggerConfig {
  host: string;
  port: number;
  token: string;
  enabled: boolean;
}

const LoggerContext = React.createContext(null as any);

export const LoggerProvider = ({ children }: IChildren) => {
  const metamask = useMetaMask();
  const { account, status: metaMaskStatus, chainId } = metamask;
  const { transactionStatus } = useTransactionProcess();
  const { balances, status: walletStatus } = useWalletData();
  const { bnbBalance, busdBalance } = balances;
  const origin = window.location.origin;
  const pathName = window.location.pathname;
  const currentHost = origin + pathName;

  React.useEffect(() => {
    (globalThis as any).loggerBody = {
      accessCode: getRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE),
      metaMaskStatus,
      walletAddress: account,
      requiredNetwork: `${blockchainConfig.BSC.name}, ${blockchainConfig.BSC.chainId}`,
      currentNetworkChainId: chainId,
      host: currentHost,
      bnbBalance: walletStatus === "success" ? bnbBalance.toString() : "0",
      busdBalance: walletStatus === "success" ? busdBalance.toString() : "0",
      transactionStatus,
      appStage,
      userAgent: navigator.userAgent
    };
  }, [account, bnbBalance, busdBalance, metaMaskStatus, transactionStatus, chainId, walletStatus, currentHost]);

  React.useEffect(() => {
    if (metaMaskStatus === "connected" || metaMaskStatus === "notConnected" || metaMaskStatus === "unavailable") {
      const reqBody = (globalThis as any).loggerBody;
      paperTrailClientInstance.info(reqBody, `Current MetaMask status is ${metaMaskStatus}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaMaskStatus]);

  React.useEffect(() => {
    const reqBody = (globalThis as any).loggerBody;
    paperTrailClientInstance.info(reqBody, `Current wallet address is ${account}`);
  }, [account]);

  React.useEffect(() => {
    const reqBody = (globalThis as any).loggerBody;
    paperTrailClientInstance.info(reqBody, `Current network ChainId is ${chainId}`);
  }, [chainId]);

  React.useEffect(() => {
    if (walletStatus === "success") {
      const reqBody = (globalThis as any).loggerBody;
      paperTrailClientInstance.info(reqBody, `Current balances updated`);
    }
  }, [balances, walletStatus]);

  React.useEffect(() => {
    const reqBody = (globalThis as any).loggerBody;
    const userLeavesApp = () => paperTrailClientInstance.info(reqBody, "USER LEAVES APP");

    window.addEventListener("beforeunload", userLeavesApp);

    return () => window.removeEventListener("beforeunload", userLeavesApp);
  }, []);

  return <LoggerContext.Provider value={undefined}>{children}</LoggerContext.Provider>;
};
