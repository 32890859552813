import React from "react";

interface Icon {
  className: string;
}

const OfflineIcon = ({ className }: Icon) => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M51.9999 53.2558V39.9907" stroke="#E7A525" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M52 63.8678V63.9948" stroke="#E7A525" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M103 52C103 80.1671 80.1671 103 52 103C23.8329 103 1 80.1671 1 52C1 23.8329 23.8329 1 52 1C80.1671 1 103 23.8329 103 52Z"
      stroke="#E7A525"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M77.5001 52C77.5001 80.1671 66.0837 103 52.0001 103C37.9166 103 26.5001 80.1671 26.5001 52C26.5001 23.8329 37.9166 1 52.0001 1C66.0837 1 77.5001 23.8329 77.5001 52Z"
      stroke="#E7A525"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M90.243 18.2732C80.901 23.5651 67.2266 26.8955 52 26.8955C36.7734 26.8955 23.0991 23.5651 13.7571 18.2732"
      stroke="#E7A525"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M51.9999 1V26.8951" stroke="#E7A525" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M90.243 85.7274C80.901 80.4355 67.2266 77.1051 52 77.1051C36.7734 77.1051 23.0991 80.4355 13.7571 85.7274"
      stroke="#E7A525"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M51.9999 103V77.1051" stroke="#E7A525" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M1 52.0002H26.5564" stroke="#E7A525" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M77.4575 52.0002H103" stroke="#E7A525" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

export default OfflineIcon;
