import Stack from "@components/arrangement/Stack/Stack";
import React from "react";

export interface FooterActionProps {
  img: string;
  title: string;
  description: string;
  buttonLabel: string;
  action: () => void;
}

const FooterAction: React.FC<FooterActionProps> = ({
  action,
  buttonLabel,
  description: desc,
  img,
  title
}: FooterActionProps) => {
  return (
    <Stack alignItems="flex-start" justifyContent="space-between" className="xs-3 footer-action">
      <div>
        <img src={img} alt="footer-logo-1.svg" className="mb-3 image" />
        <p className="title p1 medium white-2 mb-1">{title}</p>
        <p className="p2 white-2 mb-1 description">{desc}</p>
      </div>
      <button onClick={action} className="button white">
        {buttonLabel} &nbsp;&nbsp;&gt;
      </button>
    </Stack>
  );
};

export default FooterAction;
