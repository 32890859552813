import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import { checkSaleStageStatus } from "../utils/checkSaleStageStatus";
import { calcWidthSaleStageBar } from "../utils/calcWidthSaleStageBar";
import { generateBarTextStatus } from "../utils/generateBarTextStatus";
import "./SaleStage.scss";

export type SaleStageType = {
  title: string;
  subtitle?: string;
  description?: string;
  maxRound: number;
  currentRound: number;
};

const SaleStage = ({ title, subtitle = "", description = "", maxRound, currentRound }: SaleStageType) => {
  if (currentRound < 0 || maxRound < 0)
    throw new Error(`Current ${title} round and max ${title} rounds cannot be negative`);

  const saleStageStatus = checkSaleStageStatus(currentRound, maxRound);
  const witdhSaleStageBar = calcWidthSaleStageBar(currentRound, maxRound);
  const saleStageClass = `sale-stage sale-stage-${saleStageStatus}`;
  return (
    <Stack className={saleStageClass}>
      <h6 className="p0 white uppercase sale-stage-title">{title}</h6>
      <div className="sale-stage-bar" style={{ width: `${witdhSaleStageBar}px` }}>
        <img src="/images/success-icon-black.svg" alt="success icon" />
        <p className="black bar-status">{generateBarTextStatus(saleStageStatus)}</p>
      </div>
      <div className="sale-stage-info">
        <p className="white-2 subtitle">{subtitle}</p>
        <p className="white-2 description">{description}</p>
      </div>
    </Stack>
  );
};

export default SaleStage;
