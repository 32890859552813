import React from "react";
import Motion from "../Motion/Motion";
import "./Spinner.scss";

type SpinnerVariant = "black" | "orange";

interface SpinnerProps {
  variant?: SpinnerVariant;
  className?: string;
}

const Spinner = ({ variant = "orange", className = "" }: SpinnerProps) => {
  return (
    <Motion duration={4} spin={true} className={`spinner ${className}`}>
      {variant === "black" && <img alt="spinner" src="/images/spinner-black.png" className="spinner" />}
      {variant === "orange" && <img alt="spinner" src="/images/spinner.png" className="spinner" />}
    </Motion>
  );
};

export default Spinner;
