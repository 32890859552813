import React from "react";
import TableBody from "./TableBody/TableBody";
import TableHead from "./TableHead/TableHead";
import "./OrderHistory.scss";

const OrderHistory = () => {
  return (
    <div className="order-history">
      <p className="p0 table-title mb-2 white">Your order history</p>
      <hr className="mb-3 bg-white" />
      <TableHead />
      <TableBody />
    </div>
  );
};

export default OrderHistory;
