import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Dots from "@sharedComponents/Dots/Dots";
import "./GoldenCard.scss";

interface GoldenCardProps {
  receivedBalance: number | string;
  receivedCurrency?: "G2N" | string;
  loadingData?: boolean;
}

const GoldenCard = ({
  receivedBalance,
  receivedCurrency = "G2N",
  loadingData = false,
  children
}: GoldenCardProps & IChildren) => {
  return (
    <div className="golden-card">
      {loadingData ? (
        <Stack alignItems="center" justifyContent="center" fullSpace>
          <p className="p1 relative">
            Loading data
            <Dots />
          </p>
        </Stack>
      ) : (
        <Stack alignItems="flex-end" className="golden-card-content">
          <Group colGap={8}>
            <p className="p0 token-name">G2N Token</p>
            <img src="/images/go2nft-golden-card.svg" alt="go2nft logo" />
          </Group>
          <p className="p1 mt-6">Total G2N Token balance</p>

          <Group alignItems="flex-start" colGap={4}>
            <h5 className="received-balance">{receivedBalance}</h5>
            <p className="received-currency bold">{receivedCurrency}</p>
          </Group>
        </Stack>
      )}

      {children}
    </div>
  );
};

export default GoldenCard;
