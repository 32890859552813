import React from "react";
import Group from "@components/arrangement/Group/Group";
import Cell from "../Cell/Cell";
import "./TableHead.scss";

const tableHead = [
  { label: "Amount", longer: false },
  { label: "Token", longer: false },
  { label: "Date", longer: false },
  { label: "TxHash", longer: true }
];

const TableHead = () => {
  return (
    <Group justifyContent="flex-start" colGap={22} className="table-head">
      {tableHead.map(({ label, longer }, index) => {
        return (
          <Cell center={index === 0} key={index} longer={longer}>
            <p className="p2 white-2">{label}</p>
          </Cell>
        );
      })}
    </Group>
  );
};

export default TableHead;
