import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Dots from "@pages/shared-components/Dots/Dots";
import React from "react";

const LoadingPresaleCardContent = () => {
  return (
    <Stack alignItems="center" justifyContent="center" rowGap={36} fullSpace>
      <img src="/images/g2n-82px.svg" alt="g2n logo" />
      <Group className="white-0" colGap={0}>
        <p className="p1 relative">
          Getting data
          <Dots />
        </p>
      </Group>
    </Stack>
  );
};

export default LoadingPresaleCardContent;
