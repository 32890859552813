import React from "react";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import { addSpaceEveryThirdDigit } from "src/utils/addSpaceEveryThirdDigit";
import VestingHoverable from "./VestingHoverable/VestingHoverable";
import DiscountPercent from "./DiscountPercent/DiscountPercent";

const NormalPresaleCardContent = ({
  discountPercent,
  busdCost,
  g2nPriceInBusd,
  g2nDiscountPriceInBusd,
  g2nAmountToGet,
  currency,
  isCardDisabled,
  cardVariant,
  handleBuy
}: any) => {
  const regularPriceInCents = (Math.trunc(g2nPriceInBusd * 10000) / 100).toString().replace(".", ",");
  const discountPriceInCents = (Math.trunc(g2nDiscountPriceInBusd * 10000) / 100).toString().replace(".", ",");

  return (
    <Stack alignItems="center" className="content">
      {cardVariant === "primary" && <p className="best-price white">Best price</p>}

      <Stack className="prices" alignItems="center" maxContent>
        <Group className="old-price">
          <p className="white currency">¢</p>
          <h3 className="value white">{regularPriceInCents}</h3>
          <p className="p2 ico white">ICO</p>
          <img src="/images/strikethrough.svg" alt="strikethrough" className="strikethrough" />
        </Group>

        <Group className="discount-price">
          <p className="success-color currency">¢</p>
          <h3 className="value success-color">{discountPriceInCents}</h3>
          <p className="p2 ico success-color">Private Sale</p>
        </Group>
        <img src="/images/arrow-indicator.svg" alt="" className="arrow" />
      </Stack>

      <Stack className="g2n-result" maxContent alignItems="center">
        <p className="white-0 p1">You will get:</p>
        <p className="white-0 p1">{addSpaceEveryThirdDigit(g2nAmountToGet)} G2N</p>
      </Stack>

      <Button
        className={`${cardVariant === "primary" && !isCardDisabled ? "shine" : ""} c2a`}
        variant={isCardDisabled ? "secondary" : cardVariant}
        onClick={handleBuy}
      >
        Invest <p className="bolder">{addSpaceEveryThirdDigit(busdCost)}</p> {currency}
      </Button>
      <p className="card-additional-text p2 white-2">Tokens will be released during the</p>
      <VestingHoverable />
      <img src="/images/card-approve.svg" alt="approve" className="card-approve" />
      <DiscountPercent value={discountPercent} variant={cardVariant} />
    </Stack>
  );
};

export default NormalPresaleCardContent;
