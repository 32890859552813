import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import "./HowToBuyModal.scss";
import { HOW_TO_BUY_VIDEO } from "@data/constants";
import { useModalsState } from "@contexts/ModalsStateContext";

const HowToBuyModal = () => {
  const { closeHowToBuy } = useModalsState();

  return (
    <Backdrop open onClick={closeHowToBuy}>
      <Portal>
        <Stack justifyContent="center" alignItems="center" className="outer-modal">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <button onClick={closeHowToBuy} className="close-icon">
                <img src="/images/close-icon.svg" alt="close icon" />
              </button>
              <Stack className="how-to-buy-modal" alignItems="center">
                <h6 className="title white">Watch how to buy</h6>
                <iframe
                  width="500px"
                  height="278px"
                  className="video"
                  src={HOW_TO_BUY_VIDEO}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default HowToBuyModal;
