import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import Group from "@components/arrangement/Group/Group";
import Spinner from "@components/ux/Spinner/Spinner";
import "./Button.scss";

export type ButtonVariants = "primary" | "secondary";

export interface ButtonBaseProps {
  variant?: ButtonVariants;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
  onBlur?: () => void;
}

type ButtonProps = ButtonBaseProps & IChildren;

const Button = ({
  children,
  variant,
  disabled = false,
  loading = false,
  className = "",
  onClick = () => {},
  onBlur = () => {}
}: ButtonProps) => {
  const buildClass = `button ${variant} ${className}`;

  return (
    <button
      className={buildClass}
      onClick={(e) => {
        e.preventDefault();
        !loading && onClick();
      }}
      onBlur={onBlur}
      disabled={disabled}
    >
      <Group colGap={10}>
        {children}
        {loading && <Spinner variant="black" className="sizes" />}
      </Group>
    </button>
  );
};

export default Button;
