import React from "react";
import { appStage } from "@config/config";
import { paths } from "@data/paths";
import { Navigate } from "react-router-dom";
import { TransactionProcessProvider } from "@contexts/TransactionProcessContext";
import DoneTransactionModal from "@pages/Presale/Modals/DoneTransactionModal/DoneTransactionModal";

const Development = () => {
  if (appStage === "prod") return <Navigate to={paths.accessPage} />;

  return (
    <TransactionProcessProvider>
      <DoneTransactionModal />
    </TransactionProcessProvider>
  );
};

export default Development;
