import React from "react";
import Button from "@components/dataEntry/Buttons/Button/Button";
import "./AccessButton.scss";
import { useAccessCodeContext } from "@contexts/AccessCodeContext";
import { paperTrailClientInstance } from "@services/papertrailInstance";

const AccessButton = () => {
  const { verifyingAccessCode, validateAccessCode, accessCode } = useAccessCodeContext();

  const handleAccessButton = async () => {
    const reqBody = (globalThis as any).loggerBody;
    paperTrailClientInstance.info(reqBody, `User tries to enter page using accessCode: ${accessCode}`);
    validateAccessCode();
  };

  return (
    <Button className="shine" onClick={handleAccessButton} loading={verifyingAccessCode} variant={"primary"}>
      Let me in!
    </Button>
  );
};

export default AccessButton;
