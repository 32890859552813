import React from "react";
import AccessPage from "./AccessPage";
import AccessPageGuard from "./Guards/AccessPageGuard";

const AccessPageWithGuards = () => {
  return (
    <AccessPageGuard>
      <AccessPage />
    </AccessPageGuard>
  );
};

export default AccessPageWithGuards;
