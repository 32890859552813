import { IChildren } from "@components/interfaces/IChildren";
import AccessCodeGuard from "@features/guards/AccessCodeGuard";
import React from "react";
import PresaleProviders from "../Providers/PresaleProviders";

const PresaleGuards = ({ children }: IChildren) => {
  return (
    <AccessCodeGuard>
      <PresaleProviders>{children}</PresaleProviders>
    </AccessCodeGuard>
  );
};

export default PresaleGuards;
