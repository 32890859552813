import React from "react";
import { useModalsState } from "@contexts/ModalsStateContext";
import Group from "@components/arrangement/Group/Group";
import Spinner from "@components/ux/Spinner/Spinner";
import "./SwitchNetwork.scss";

const SwitchNetwork = () => {
  const { switchingNetwork, openSwitchNetwork } = useModalsState();

  const handleSwitchNetwork = () => {
    openSwitchNetwork();
  };

  return (
    <button className={`switch-network ${switchingNetwork ? "connecting" : ""}`} onClick={handleSwitchNetwork}>
      <Group justifyContent="space-between" alignItems="center" colGap={33}>
        <Group justifyContent="flex-start" colGap={8} alignItems="center">
          <img src="/images/metamask-logo.svg" alt="metamask logo" />
          <span>Switch network</span>
        </Group>
        {switchingNetwork && <Spinner className="spinner" />}
      </Group>
    </button>
  );
};

export default SwitchNetwork;
