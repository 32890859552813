import React from "react";
import SaleStage, { SaleStageType } from "./SaleStage/SaleStage";
import "./SalesStages.scss";

interface SalesStagesProps {
  salesStages: SaleStageType[];
}

const SalesStages = ({ salesStages }: SalesStagesProps) => {
  return (
    <div className="sales-stages">
      {salesStages.map(({ title, subtitle, description, maxRound, currentRound }, index) => {
        const generateTitle = `${index + 1}. ${title}`;
        return (
          <SaleStage
            key={index}
            title={generateTitle}
            subtitle={subtitle}
            description={description}
            maxRound={maxRound}
            currentRound={currentRound}
          />
        );
      })}
    </div>
  );
};

export default SalesStages;
