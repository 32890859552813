import React from "react";
import MailingForm from "@pages/Presale/sections/Mailing/MailingForm/MailingForm";
import "./Mailing.scss";

const Mailing = () => {
  return (
    <div className="mailing">
      <MailingForm />
    </div>
  );
};

export default Mailing;
