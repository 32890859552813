import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import { isMobile } from "react-device-detect";
import UnavailableOnMobileModal from "@pages/shared-components/Modals/UnavailableOnMobile/UnavailableOnMobileModal";

const MobileGuard = ({ children }: IChildren) => {
  if (isMobile) {
    return <UnavailableOnMobileModal />;
  }

  return <>{children}</>;
};

export default MobileGuard;
