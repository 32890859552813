import InnerNav from "@pages/shared-components/InnerNav";
import React from "react";
import "./Navbar.scss";

const Navbar = () => {
  return (
    <div className="navbar">
      <InnerNav />
    </div>
  );
};

export default Navbar;
