import { paperTrailClientInstance } from "@services/papertrailInstance";

export interface IToken {
  address: string;
  symbol: string;
  decimals: number;
  image: string;
}

const addTokenToMetamask = (token: IToken) => async () => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    console.log("Metamask is not installed");
    return;
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          ...token
        }
      }
    });
  } catch (error) {
    const reqBody = (globalThis as any).loggerBody;
    paperTrailClientInstance.error(reqBody, `Could't add token to metamask, ${error}`);
  }
};

export default addTokenToMetamask;
