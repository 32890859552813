import React from "react";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import TransactionStepper from "./TransactionStepper/TransactionStepper";
import Motion from "@components/ux/Motion/Motion";
import { useTransactionProcess } from "@contexts/TransactionProcessContext";
import { numberToLocaleString } from "src/utils/numberToLocaleString";
import { TRANSACTION_LONGER_TIME } from "@config/config";
import "./TwoStepTransactionModal.scss";

const TwoStepTransactionModal = () => {
  const [isLongerTime, setIsLongerTime] = React.useState(false);
  const { currentG2NTokens, currentAllowance, transactionStatus } = useTransactionProcess();

  React.useEffect(() => {
    if (transactionStatus === "secondStepProcessing") {
      const handleTimeout = setTimeout(() => {
        setIsLongerTime(true);
      }, TRANSACTION_LONGER_TIME);

      return () => clearTimeout(handleTimeout);
    }
  }, [transactionStatus]);

  return (
    <Backdrop open>
      <Portal>
        <Stack justifyContent="center" alignItems="center">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="two-step-transaction" alignItems="center">
                <h5 className="white mb-2">You are Buying</h5>
                <Group alignItems="center" colGap={16} className="mb-4 bought-tokens-info">
                  <Group colGap={8} alignItems="center">
                    <img src="/images/go2nft-logo-without-layer.svg" alt="go2nft token" />
                    <p className="white-2">{numberToLocaleString(currentG2NTokens)} G2N</p>
                  </Group>
                  <p className="white-2">for</p>
                  <Group colGap={8} alignItems="center">
                    <p className="white-2">{numberToLocaleString(currentAllowance)} BUSD</p>
                    <img src="/images/busd-logo-without-layer.svg" alt="busd token" />
                  </Group>
                </Group>
                <img src="/images/metamask-logo.svg" alt="metamask logo" className="mb-4 logo" height={88} width={94} />
                <h5 className="title white mb-5">Follow the steps on your Metamask</h5>
                {isLongerTime && (
                  <Motion appear={true} duration={2} className="mb-6">
                    <p className="longer-time-info">Transaction in progress. This may take a few seconds.</p>
                  </Motion>
                )}
                <TransactionStepper />
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default TwoStepTransactionModal;
