import React from "react";

const PROBLEM_MESSAGE_FIELD = {
  label: "Write Your message",
  placeholder: "Describe your problem",
  type: "textarea"
};

const useFormProblemMessage = () => {
  const [problemMessage, setProblemMessage] = React.useState<string>("");

  const handleSetProblemMessage = (message: string) => setProblemMessage(message);

  const resetProblemMessage = () => setProblemMessage("");

  return { problemMessage, PROBLEM_MESSAGE_FIELD, handleSetProblemMessage, resetProblemMessage };
};

export default useFormProblemMessage;
