import React from "react";
import Group from "@components/arrangement/Group/Group";
import { BalanceState } from "../WalletBalanceContainer";
import Spinner from "@components/ux/Spinner/Spinner";
import { blockchainConfig } from "@config/config";
import "./WalletBalance.scss";

export interface WalletBalanceInterface {
  busdBalance: string;
  balanceState: BalanceState;
  error: React.ReactNode | null;
}

const WalletBalance = ({ balanceState, error, busdBalance }: WalletBalanceInterface) => {
  const balanceOutput = () => {
    if (balanceState === "loading") {
      return <Spinner />;
    }
    if (
      balanceState === "default" ||
      balanceState === "too-low-bnb" ||
      balanceState === "too-low-both" ||
      balanceState === "too-low-busd"
    ) {
      return busdBalance;
    }
    if (balanceState === "failed-contract" || balanceState === "failed-wallet") {
      return "-";
    }
  };

  return (
    <Group colGap={8} justifyContent="center" className="wallet">
      <div className="balance white">{balanceOutput()}</div>
      {error && <article className="article">{error}</article>}
      <p className={`currency medium`}>{blockchainConfig.BSC.stableCoin}</p>
    </Group>
  );
};

export default WalletBalance;
