import React from "react";
import { BigNumber, ContractReceipt } from "ethers";
import axios from "axios";
import { useMetaMask } from "metamask-react";
import { blockchainConfig, BSC_SCAN_PROXY_URL, NEW_TRANSACTION_HIGHLIGHT_TIME } from "@config/config";
import { paperTrailClientInstance } from "@services/papertrailInstance";

export interface Order<T extends string | BigNumber> {
  txHash: string;
  blockNumber: number;
  func: string;
  value: T;
  timestamp: number;
  url: string;
  from: string;
}

export interface OrderHistory {
  status: "loading" | "success" | "failed";
  orders: Order<BigNumber>[];
  error: Error | null;
  refetchOrder: () => void;
  addNewTransaction: (d: Order<BigNumber>, args: any[]) => void;
}

const initialOrderHistory = (): OrderHistory => ({
  status: "loading",
  orders: [],
  error: null,
  refetchOrder: () => {},
  addNewTransaction: (d: Order<BigNumber>, args: any[]) => {}
});

interface AdditionalData {
  firstAsNew: boolean;
  setNewTransactionHighlight: () => void;
}

const OrderHistoryContext = React.createContext({
  ...initialOrderHistory(),
  firstAsNew: false,
  setNewTransactionHighlight: () => {}
});

const fetchOrders = async (address: string): Promise<Order<BigNumber>[]> => {
  const res = await axios.get(BSC_SCAN_PROXY_URL, { params: { address } });

  const data: Order<string>[] = res.data;

  const transformedData = data.map((tx) => ({ ...tx, value: BigNumber.from(tx.value) }));

  return transformedData;
};

export const OrderHistoryProvider = ({ children }: React.PropsWithChildren) => {
  const [data, setData] = React.useState(initialOrderHistory());
  const [firstAsNew, setFirstAsNew] = React.useState(false);

  const setNewTransactionHighlight = () => setFirstAsNew(true);
  const resetTransactionHighlight = () => setFirstAsNew(false);

  const { status, account, chainId } = useMetaMask();

  React.useEffect(() => {
    if (status === "connected" && !!account && chainId === blockchainConfig.BSC.chainId) {
      fetchOrder();
    }
    // eslint-disable-next-line
  }, [status, account, chainId]);

  React.useEffect(() => {
    if (firstAsNew) {
      setTimeout(() => {
        resetTransactionHighlight();
      }, NEW_TRANSACTION_HIGHLIGHT_TIME);
    }
  }, [firstAsNew]);

  const fetchOrder = async () => {
    if (!account || status !== "connected" || chainId !== blockchainConfig.BSC.chainId) return;

    setData(initialOrderHistory());

    const reqBody = (globalThis as any).loggerBody;
    try {
      paperTrailClientInstance.info(reqBody, `Fetching orders history`);
      const orders = await fetchOrders(account);
      setData((d) => ({ ...d, orders, status: "success", error: null }));
    } catch (e: any) {
      paperTrailClientInstance.error(reqBody, `Couldnt get orders history, ${e}`);
      setData((d) => ({ ...d, status: "failed", error: e }));
    }
  };

  const addNewTransaction = (transaction: ContractReceipt, args: any[]) => {
    const newTransaction: Order<BigNumber> = {
      blockNumber: transaction.blockNumber,
      from: transaction.from,
      func: "buy",
      timestamp: Math.floor(Date.now() / 1000),
      txHash: transaction.transactionHash,
      url: `${blockchainConfig.BSC.scannerUrl}/tx/${transaction.transactionHash}`,
      value: args[2]
    };

    setData((d) => ({ ...d, orders: [newTransaction, ...d.orders], status: "success" }));
    // setNewTransactionHighlight();
  };

  const contextValue: OrderHistory & AdditionalData & any = {
    ...data,
    refetchOrder: fetchOrder,
    firstAsNew,
    setNewTransactionHighlight,
    addNewTransaction
  };

  return <OrderHistoryContext.Provider value={contextValue}>{children}</OrderHistoryContext.Provider>;
};

export const useOrderHistory = () => React.useContext(OrderHistoryContext);
