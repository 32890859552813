import { HEADERS_API_KEY_KEY, HEADERS_RECAPTCHA_KEY } from "@config/config";

export type IRequestMethods = "GET" | "POST" | "PUT" | "DELETE";

export interface IEmailServiceConfig {
  serviceURL: string;
  serviceApiKey: string;
  mailingTimeout: number;
}

export interface EmailBody {
  email: string;
  message: string;
  address: string;
  accessToken: string;
  captchaToken: string;
  error?: string;
}

export class EmailService {
  constructor(private config: IEmailServiceConfig) {}

  async sendRequest(
    url: string,
    method: IRequestMethods,
    headers: HeadersInit | undefined,
    body?: BodyInit | null | undefined,
    signal?: AbortSignal
  ): Promise<Response> {
    const response = await fetch(url, {
      method,
      headers,
      body,
      signal
    });

    return response;
  }

  private async sendEmail(url: string, body: BodyInit | null | undefined, captchaToken?: string): Promise<Response> {
    const method: IRequestMethods = "POST";
    const abortController = new AbortController();
    const { signal } = abortController;

    const basicHeaders = {
      [HEADERS_API_KEY_KEY]: this.config.serviceApiKey,
      "Content-Type": "application/json"
    };
    const headersWithCaptcha = {
      ...basicHeaders,
      [HEADERS_RECAPTCHA_KEY]: captchaToken
    };
    const headers = captchaToken ? headersWithCaptcha : basicHeaders;

    const sendingTimeout = setTimeout(() => abortController.abort(), this.config.mailingTimeout);

    const response = await fetch(url, {
      method,
      headers,
      signal,
      body
    });

    clearTimeout(sendingTimeout);

    return response;
  }

  async contactEmail(emailBody: EmailBody) {
    const mailerServiceName = "g2nPrivateSellContactEmail";
    const url = `${this.config.serviceURL}/${mailerServiceName}/contact`;
    const body = JSON.stringify({ ...emailBody });

    return await this.sendEmail(url, body, emailBody.captchaToken);
  }

  async problemEmail(emailBody: EmailBody) {
    const mailerServiceName = "g2nPrivateSellErrorEmail";
    const url = `${this.config.serviceURL}/${mailerServiceName}/contact`;
    const trueMessage = emailBody.message.length > 0 ? emailBody.message : " ";

    const body = JSON.stringify({ ...emailBody, message: trueMessage });

    return await this.sendEmail(url, body, emailBody.captchaToken);
  }
}
