import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import UnsupportedBrowser from "@pages/shared-components/Modals/UnsupportedBrowser/UnsupportedBrowser";

const BrowserGuard = ({ children }: IChildren) => {
  let sUsrAg = navigator.userAgent;

  if (
    sUsrAg.indexOf("Firefox") === -1 ||
    sUsrAg.indexOf("Chrome") === -1 ||
    sUsrAg.indexOf("Brave") === -1 ||
    sUsrAg.indexOf("Edge") === -1 ||
    sUsrAg.indexOf("Opera") === -1 ||
    sUsrAg.indexOf("OPR") === -1
  ) {
    return <>{children}</>;
  }

  return <UnsupportedBrowser />;
};

export default BrowserGuard;
