import Hero from "./sections/Hero/Hero";
import Timeline from "./sections/Timeline/Timeline";
import Mailing from "./sections/Mailing/Mailing";
import PresaleCards from "./sections/PresaleCards/PresaleCards";
import PresaleUtils from "./sections/PresaleUtils/PresaleUtils";
import TransactionsHistory from "./sections/TransactionsHistory/TransactionsHistory";
import PresaleModals from "./Modals/PresaleModals";
import "./Presale.scss";

const Presale = () => {
  return (
    <div className="presale">
      <Hero />
      <Timeline />
      <PresaleCards />
      <TransactionsHistory />
      <Mailing />
      <PresaleUtils />
      <PresaleModals />
    </div>
  );
};

export default Presale;
