import React from "react";
import Group from "@components/arrangement/Group/Group";
import PresaleCard from "./PresaleCard/PresaleCard";
import { SaleData, useContractData } from "@contexts/ContractDataContext";
import "./PresaleCards.scss";

const sortHigherToLower = (saleData: SaleData[]) => {
  return saleData.sort((a, b) => {
    if (a.discountPrice > b.discountPrice) {
      return 1;
    } else {
      return -1;
    }
  });
};

const reorder = (saleData: SaleData[]) => {
  if (saleData.length < 3) {
    return Array(3).fill(saleData[0]);
  } else {
    const firstItem = saleData[0];
    const secondItem = saleData[1];
    saleData[0] = secondItem;
    saleData[1] = firstItem;
    return saleData;
  }
};

const PresaleCards = () => {
  const contractData = useContractData();
  const higherToLower = sortHigherToLower(contractData.saleData);
  const reordered = reorder(higherToLower);

  return (
    <div className="presale-cards" id="plans">
      <img src="/images/small-flare.svg" alt="flare" className="flare" />
      <Group colGap={30} alignItems="center">
        {reordered.map(({ basicPrice, busdCost, discountPercent, discountPrice, g2nToGet }, index) => {
          return (
            <PresaleCard
              cardVariant={index === 1 ? "primary" : "secondary"}
              discountPercent={discountPercent}
              busdCost={busdCost}
              currency={"BUSD"}
              g2nPriceInBusd={basicPrice}
              g2nDiscountPriceInBusd={discountPrice}
              g2nAmountToGet={g2nToGet}
              contractDataStatus={contractData.status}
              key={index}
            />
          );
        })}
      </Group>
    </div>
  );
};

export default PresaleCards;
