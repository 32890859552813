import React from "react";
import { useNotificationContext } from "@contexts/NotificationContext";
import Notification from "@components/overlay/Notification/Notification";

const FeedbackNotification = () => {
  const { notification, removeNotification } = useNotificationContext();
  const { variant, title, message } = notification || {};

  if (notification) {
    return <Notification variant={variant} title={title} message={message} close={removeNotification} />;
  }

  return <></>;
};

export default FeedbackNotification;
