import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import Dots from "@sharedComponents/Dots/Dots";
import { blockchainConfig } from "@config/config";
import "./InvestedCard.scss";

interface InvestedCardProps {
  investedBalance: number | string;
  investedCurrency?: "BUSD" | string;
  loadingData?: boolean;
}

const InvestedCard = ({ investedBalance, investedCurrency = "BUSD", loadingData = false }: InvestedCardProps) => {
  return (
    <div className="invested-card">
      {loadingData ? (
        <Stack alignItems="center" justifyContent="center" className="invested-card-loading" fullSpace>
          <p className="p1 white-2 relative">
            Loading data
            <Dots />
          </p>
        </Stack>
      ) : (
        <Stack alignItems="flex-end" className="invested-card-content">
          <Group colGap={8}>
            <p className="p0 white-2">{blockchainConfig.BSC.name}</p>
            <img src="/images/bsc-signet.svg" alt="Binance Smart Chain logo" />
          </Group>
          <p className="p1 mt-3 white">Total investment balance</p>

          <Group alignItems="flex-start" colGap={4} pt={4}>
            <h6 className="white">{investedBalance}</h6>
            <p className="invested-currency bold white">{investedCurrency}</p>
          </Group>
        </Stack>
      )}
    </div>
  );
};

export default InvestedCard;
