import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMetaMask } from "metamask-react";

import Stack from "@components/arrangement/Stack/Stack";
import Input from "@components/dataEntry/Input/Input";
import TextArea from "@components/dataEntry/TextArea/TextArea";
import Button from "@components/dataEntry/Buttons/Button/Button";
import useFetch from "@hooks/useFetch";
import useCaptcha from "@hooks/useCaptcha";
import useFormEmail from "@hooks/useFormEmail";
import useFormMessage from "@hooks/useFormMessage";
import { useNotificationContext } from "@contexts/NotificationContext";
import { emailService } from "@services/emailServiceInstance";
import { appStage, MAILING_CAPTCHA_KEY } from "@config/config";
import { getRecordSessionStorage, SESSION_STORAGE_ACCESS_CODE } from "src/utils/sessionStorage/sessionStorage";

import "./MailingForm.scss";
import { EmailBody } from "@services/EmailService";

const MailingForm = () => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const { email, EMAIL_FIELD, handleSetEmail, resetEmail } = useFormEmail();
  const { message, MESSAGE_FIELD, handleSetMessage, resetMessage } = useFormMessage();
  const { addNotification } = useNotificationContext();
  const { captchaToken, handleCaptcha } = useCaptcha();
  const { account: accountAddress } = useMetaMask();

  const accessCode = getRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE);

  const emailBody: EmailBody = {
    email: email.value,
    message: message.value,
    address: accountAddress ?? "",
    accessToken: accessCode ?? "",
    captchaToken: captchaToken ?? ""
  };

  const { doFetch, isLoading, data, error } = useFetch(async () => emailService.contactEmail(emailBody));

  const correctEmailValue = Boolean(email.error) || !Boolean(email.value);
  const correctMessageValue = Boolean(message.error) || !Boolean(message.value);

  const sendButtonDisabledDev = isLoading || correctEmailValue || correctMessageValue;
  const sendButtonDisabledProd = !Boolean(captchaToken) || sendButtonDisabledDev;

  const sendButtonDisabled = appStage === "dev" ? sendButtonDisabledDev : sendButtonDisabledProd;

  const resetCaptcha = () => {
    const recaptcha = recaptchaRef.current;
    if (!recaptcha) return;
    recaptcha.reset();
  };

  const resetForm = React.useCallback(() => {
    resetEmail();
    resetMessage();
    resetCaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //handle incoming data
  React.useEffect(() => {
    if (!data) return;
    const status = data.ok;

    if (status) {
      addNotification("success", "Thank you", "Your message has been sent.");
      resetForm();
      return;
    }
    if (!status || error) {
      addNotification("error", "Failed to send message.", "There was an error, please try again later.");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, resetForm]);

  return (
    <Stack className="mailing-form" alignItems="center">
      <h4 className="title white mb-2">Need Help?</h4>
      <p className="p0 white-0 mb-3 description">Please send us a message</p>
      <Stack alignItems="center">
        <Input
          value={email.value}
          error={email.error}
          label={EMAIL_FIELD.label}
          placeholder={EMAIL_FIELD.placeholder}
          onChange={handleSetEmail}
          className="mb-6"
          type="text"
        />
        <TextArea
          className="mb-5 text-area"
          value={message.value}
          error={message.error}
          label={MESSAGE_FIELD.label}
          placeholder={MESSAGE_FIELD.placeholder}
          onChange={handleSetMessage}
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={MAILING_CAPTCHA_KEY}
          onChange={(token) => handleCaptcha(token)}
          theme="dark"
        />
        <Button
          variant="secondary"
          className="mt-2"
          disabled={sendButtonDisabled}
          loading={isLoading}
          onClick={() => doFetch(`User sends regular email: ${JSON.stringify(emailBody)}`)}
        >
          Send message
          {!isLoading && <img src="/images/messageLogo.svg" alt="message" />}
        </Button>
      </Stack>
    </Stack>
  );
};

export default MailingForm;
