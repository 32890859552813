import React from "react";
import { ethereum } from "@services/ethereumInstance";
import { BigNumber } from "ethers";
import { useMetaMask } from "metamask-react";
import { Ethereum } from "../blockchain/Ethereum";
import { paperTrailClientInstance } from "@services/papertrailInstance";
import { useModalsState } from "@contexts/ModalsStateContext";

export type FetchStatus = "loading" | "success" | "failed";

export interface WalletData {
  status: FetchStatus;
  balances: WalletBalances;
  error: Error | null;
  refetch: () => Promise<void>;
}

export interface WalletBalances {
  busdBalance: BigNumber;
  bnbBalance: BigNumber;
  contractBalance: BigNumber;
  contractMaxBalance: BigNumber;
  usdPaid: BigNumber;
}

const initialBalances = (): WalletBalances => ({
  busdBalance: BigNumber.from(0),
  bnbBalance: BigNumber.from(0),
  contractBalance: BigNumber.from(0),
  contractMaxBalance: BigNumber.from(0),
  usdPaid: BigNumber.from(0)
});

const initialWalletDataWithLoading = (): WalletData => ({
  status: "loading",
  balances: initialBalances(),
  error: null,
  refetch: async () => {}
});

const WalletDataContext = React.createContext(initialWalletDataWithLoading());

export const fetchWalletBalances = async (ethereum: Ethereum): Promise<WalletBalances> => {
  const [busd, bnb, user] = await Promise.all([
    ethereum.getUsdBalance(),
    ethereum.getEtherBalance(),
    ethereum.getUserData()
  ]);

  return {
    busdBalance: busd,
    bnbBalance: bnb,
    contractBalance: user.balance,
    contractMaxBalance: user.maxBalance,
    usdPaid: user.paid
  };
};

export const WalletDataProvider = ({ children }: React.PropsWithChildren) => {
  const [data, setData] = React.useState(initialWalletDataWithLoading());
  const { openFetchingDataError } = useModalsState();
  const { status, account, chainId } = useMetaMask();

  React.useEffect(() => {
    if (status === "connected" && !!account) {
      fetchBalances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, account, chainId]);

  const fetchBalances = async () => {
    setData(initialWalletDataWithLoading());
    const reqBody = (globalThis as any).loggerBody;
    try {
      paperTrailClientInstance.info(reqBody, `Fetching wallet balances`);
      const balances = await fetchWalletBalances(ethereum);
      setData((d) => ({ ...d, balances, status: "success" }));
    } catch (e: any) {
      paperTrailClientInstance.error(reqBody, `Couldn't get wallet balances, ${e}`);
      if (e.code === -32603) {
        console.log("JSON-RPC error");
      }
      console.log(e);
      openFetchingDataError();
      setData((d) => ({ ...d, status: "failed", error: e }));
    }
  };

  const refetchBalances = async () => {
    await fetchBalances();
  };

  const contextValue: WalletData = {
    ...data,
    refetch: refetchBalances
  };

  return <WalletDataContext.Provider value={contextValue}>{children}</WalletDataContext.Provider>;
};

export const useWalletData = () => React.useContext(WalletDataContext);
