import React from "react";
import { validateEmail } from "src/utils/validateEmail";

const EMAIL_FIELD = {
  label: "Your email address",
  placeholder: "Enter your email",
  type: "email"
};

const useFormEmail = () => {
  const [email, setEmail] = React.useState({
    value: "",
    error: ""
  });

  const handleSetEmail = (email: string) => {
    const { error } = validateEmail(email);

    if (!error) {
      setEmail((prev) => ({ ...prev, value: email, error: "" }));
      return;
    }

    setEmail((prev) => ({ ...prev, value: email, error }));
  };

  const resetEmail = () => {
    setEmail({
      value: "",
      error: ""
    });
  };

  return { email, EMAIL_FIELD, handleSetEmail, resetEmail };
};

export default useFormEmail;
