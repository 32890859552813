import React from "react";
import { CardState } from "../PresaleCard";
import "./WalletFeedback.scss";
import { useMetaMask } from "metamask-react";
import { blockchainConfig } from "@config/config";

interface WalletProps {
  cardState: CardState;
}

const WalletFeedback = ({ cardState }: WalletProps) => {
  const { chainId } = useMetaMask();
  const getError = () => {
    if (cardState === "contract-failed") {
      <p className="danger p1">Getting contract data failed.</p>;
    }
    if (cardState === "wallet-failed") {
      <p className="danger p1">Getting wallet balances failed</p>;
    }
    if (cardState === "too-low-bnb" && chainId === blockchainConfig.BSC.chainId) {
      return (
        <p className="danger p1">
          You have insufficient funds <br /> please load your BNB balance to invest.
        </p>
      );
    }
    if (cardState === "too-low-busd" && chainId === blockchainConfig.BSC.chainId) {
      return (
        <p className="danger p1">
          You have insufficient funds <br /> please load your BUSD balance to invest.
        </p>
      );
    }

    return <></>;
  };

  return <div className="wallet-feedback">{getError()}</div>;
};

export default WalletFeedback;
