export const EXCHANGE_RATE_TESTNET = 50;
export const EXCHANGE_RATE_MAINNET = 50;

export const MIN_AMOUNT_VALUE_TESTNET = 0;
export const MAX_AMOUNT_VALUE_TESTNET = 200000;

export const MIN_AMOUNT_VALUE_MAINNET = 0;
export const MAX_AMOUNT_VALUE_MAINNET = 200000;

export const INVESTMENT_CURRENCY = "BUSD";
export const TOKEN_CURRENCY = "G2N";

export const MIN_BNB_BALANCE = "0.002";
