import { BlockchainConfig, testnet, mainnet } from "@data/networks";
import {
  EXCHANGE_RATE_MAINNET,
  EXCHANGE_RATE_TESTNET,
  MAX_AMOUNT_VALUE_MAINNET,
  MAX_AMOUNT_VALUE_TESTNET,
  MIN_AMOUNT_VALUE_MAINNET,
  MIN_AMOUNT_VALUE_TESTNET
} from "@data/saleData";

export type AppStage = "dev" | "prod" | null;

export const appStage: AppStage = (process.env.REACT_APP_STAGE as AppStage) || null;

const DEBUG_MODE_ENV = process.env.REACT_APP_DEBUG_MODE || null;

const getDebugMode = (): boolean => {
  if (DEBUG_MODE_ENV === null) {
    throw new Error("Debug mode env not provided");
  }
  if (DEBUG_MODE_ENV !== "true" && DEBUG_MODE_ENV !== "false") {
    throw new Error("Debug mode has wrong value");
  }
  return DEBUG_MODE_ENV === "true";
};

const config = (): BlockchainConfig => {
  if (appStage === "dev") {
    return testnet;
  }
  if (appStage === "prod") {
    return mainnet;
  }
  throw Error("Unhandled app stage in enviroments");
};

const getMinSaleValue = () => {
  if (appStage === "dev") {
    return MIN_AMOUNT_VALUE_TESTNET;
  }
  if (appStage === "prod") {
    return MIN_AMOUNT_VALUE_MAINNET;
  }
  throw Error("Unhandled app stage in enviroments");
};

const getMaxSaleValue = () => {
  if (appStage === "dev") {
    return MAX_AMOUNT_VALUE_TESTNET;
  }
  if (appStage === "prod") {
    return MAX_AMOUNT_VALUE_MAINNET;
  }
  throw Error("Unhandled app stage in enviroments");
};

const getExchangeRate = () => {
  if (appStage === "dev") {
    return EXCHANGE_RATE_TESTNET;
  }
  if (appStage === "prod") {
    return EXCHANGE_RATE_MAINNET;
  }
  throw Error("Unhandled app stage in enviroments");
};

export const blockchainConfig = config();
export const MIN_AMOUNT_VALUE = getMinSaleValue();
export const MAX_AMOUNT_VALUE = getMaxSaleValue();
export const EXCHANGE_RATE = getExchangeRate();
export const DEBUG_MODE = getDebugMode();

const MAILING_SERVICE_URL_ENV = process.env.REACT_APP_MAILING_SERVICE_URL || null;
const MAILING_CAPTCHA_KEY_ENV = process.env.REACT_APP_MAILING_RECAPTCHA_KEY || null;
const MAILING_SERVICE_API_KEY_ENV = process.env.REACT_APP_MAILING_SERVICE_API_KEY || null;

const getMailingServiceUrl = () => {
  if (MAILING_SERVICE_URL_ENV) return MAILING_SERVICE_URL_ENV;
  throw new Error("There is no provided such env variable");
};

const getCaptchaKey = () => {
  if (MAILING_CAPTCHA_KEY_ENV) return MAILING_CAPTCHA_KEY_ENV;
  throw new Error("There is no provided such env variable");
};

const getMailingServiceApiKey = () => {
  if (MAILING_SERVICE_API_KEY_ENV) return MAILING_SERVICE_API_KEY_ENV;
  throw new Error("There is no provided such env variable");
};

export const MAILING_SERVICE_URL = getMailingServiceUrl();
export const MAILING_CAPTCHA_KEY = getCaptchaKey();
export const MAILING_API_KEY = getMailingServiceApiKey();
export const MAILING_SERVICE_TIMEOUT = 8000; //[ms]

export const HEADERS_API_KEY_KEY = "X-API-KEY";
export const HEADERS_RECAPTCHA_KEY = "recaptcha";
export const BSC_SCAN_PROXY_URL = `${MAILING_SERVICE_URL}/bsc-scan/latest`;

export const NOTIFICATION_CLEAR_TIME = 10000; //[ms]
export const NEW_TRANSACTION_HIGHLIGHT_TIME = 20000; //[ms]
export const TRANSACTION_LONGER_TIME = 10000; //[ms]

export const MAX_WIDTH_SALE_STAGE_BAR = 350; // [px]

export const CURRENT_PRIVATE_SALE_ROUND = Number(process.env.REACT_APP_CURRENT_PRIVATE_SALE_ROUND) || 0;
export const MAX_PRIVATE_SALE_ROUNDS = Number(process.env.REACT_APP_MAX_PRIVATE_SALE_ROUNDS) || 0;
export const CURRENT_VESTING_ROUND = Number(process.env.REACT_APP_CURRENT_VESTING_ROUND) || 0;
export const MAX_VESTING_ROUNDS = Number(process.env.REACT_APP_MAX_VESTING_ROUNDS) || 0;
export const CURRENT_ICO_ROUND = Number(process.env.REACT_APP_CURRENT_ICO_ROUND) || 0;
export const MAX_ICO_ROUNDS = Number(process.env.REACT_APP_MAX_ICO_ROUNDS) || 0;
