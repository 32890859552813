import { SaleStageStatus } from "../SaleStageStatus.enum";

export const generateBarTextStatus = (status: string) => {
  switch (status) {
    case SaleStageStatus.InActive:
      return "";
    case SaleStageStatus.Active:
      return "in progress";
    case SaleStageStatus.Done:
      return "done";
    default:
      return "";
  }
};
