import React from "react";
import AccessCodeInput from "./AccessCodeInput/AccessCodeInput";
import AccessButton from "./AccessButton/AccessButton";
import Stack from "@components/arrangement/Stack/Stack";
import "./AccessPage.scss";

const AccessPage = () => {
  return (
    <div className="access-page">
      <img src="/images/bg-radial-gradient.svg" className="bg-radial-gradient" alt="radial-gradient" />
      <Stack alignItems="center" className="stack">
        <img alt="logo go2nft" src="/images/go2nft-logo.svg" className="mb-7" />
        <h5 className="white mb-3 title">Lucky chosen one, welcome to an exclusive closed round for investors!</h5>
        <p className="p2 white-2 mb-10 additional">Enter your Unique Access Code below:</p>
        <form>
          <Stack alignItems="center">
            <AccessCodeInput />
            <AccessButton />
          </Stack>
        </form>
      </Stack>
    </div>
  );
};

export default AccessPage;
