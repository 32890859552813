import React from "react";
import "./GradientBorder.scss";

interface IBorder {
  radius: number;
  children: React.ReactNode;
  withBoxShadow: boolean;
  hover?: boolean;
  gradient?: 5 | 6;
  className?: string;
}

const GradientBorder = ({ radius, children, withBoxShadow, hover = false, className = "", gradient = 5 }: IBorder) => {
  return (
    <div
      className={`gradient-border-wrapper ${withBoxShadow ? "with-box-shadow" : ""} ${
        hover ? "hover" : ""
      } ${className}`}
      style={{ borderRadius: `${radius}px` }}
    >
      <div className={`gradient-border ${className} gradient-${gradient}`}>{children}</div>
    </div>
  );
};

export default GradientBorder;
