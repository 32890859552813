import { MetaMaskProvider } from "metamask-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import { AccessCodeProvider } from "@contexts/AccessCodeContext";
import { NotificationProvider } from "@contexts/NotificationContext";
import GlobalModals from "@pages/shared-components/Modals/GlobalModals";
import { ModalsStateProvider } from "@contexts/ModalsStateContext";
import { EffectsProvider } from "@contexts/EffectsContext";

export const Router = () => {
  return (
    <BrowserRouter basename="/">
      <MetaMaskProvider>
        <AccessCodeProvider>
          <NotificationProvider>
            <ModalsStateProvider>
              <EffectsProvider>
                <Routes>
                  {routes.map(({ path, page: Page, layout: Shell }) => (
                    <Route
                      path={path}
                      key={path}
                      element={
                        <Shell>
                          <Page />
                        </Shell>
                      }
                    />
                  ))}
                </Routes>
                <GlobalModals />
              </EffectsProvider>
            </ModalsStateProvider>
          </NotificationProvider>
        </AccessCodeProvider>
      </MetaMaskProvider>
    </BrowserRouter>
  );
};
