import React from "react";
import { useNavigate } from "react-router-dom";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate("/", { replace: true }); // the current entry in the history stack will be replaced
    }
  };

  return (
    <div className="not-found">
      <img src="/images/bg-radial-gradient.svg" alt="radial-gradient" className="bg-radial-gradient-top" />
      <Stack alignItems="center" justifyContent="center" className="not-found-content" fullSpace>
        <img src="/images/not-found.svg" alt="not-found" className="mb-6" />
        <h6 className="white title">Error 404! Page Not Found</h6>
        <Button variant="primary" onClick={goBack}>
          Go back
        </Button>
      </Stack>
      <img src="/images/bg-radial-gradient-2.svg" alt="radial-gradient" className="bg-radial-gradient-bottom" />
    </div>
  );
};

export default NotFound;
