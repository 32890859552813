import React from "react";
import { useMetaMask } from "metamask-react";

import Spinner from "@components/ux/Spinner/Spinner";
import Group from "@components/arrangement/Group/Group";
import { useModalsState } from "@contexts/ModalsStateContext";
import "./ConnectMetamask.scss";

const ConnectMetamask = () => {
  const { status, connect } = useMetaMask();
  const { openConnectMetamask } = useModalsState();

  const connecting = status === "connecting";

  const metamaskMessage = () => {
    if (connecting) {
      return "Connecting...";
    }

    return "Connect Metamask";
  };

  const metamaskAction = () => {
    if (status === "unavailable") {
      return openConnectMetamask;
    }

    return connect;
  };

  return (
    <button className={`connect-metamask ${connecting ? "connecting" : ""}`} onClick={metamaskAction()}>
      <Group justifyContent="space-between" alignItems="center" colGap={33}>
        <Group justifyContent="flex-start" colGap={8} alignItems="center">
          <img src="/images/metamask-logo.svg" alt="metamask logo" />
          <span>{metamaskMessage()}</span>
        </Group>
        {status === "connecting" && <Spinner className="spinner" />}
      </Group>
    </button>
  );
};

export default ConnectMetamask;
