import React from "react";
import { useMetaMask } from "metamask-react";
import { useErrorModalContext } from "@contexts/ErrorModalContext";
import { useTransactionProcess } from "@contexts/TransactionProcessContext";
import ConnectMetamaskModal from "./ConnectMetamask/ConnectMetamaskModal";
import DoneTransactionModal from "./DoneTransactionModal/DoneTransactionModal";
import SomethingWentWrong from "./SomethingWentWrong/SomethingWentWrong";
import SwitchNetworkModal from "./SwitchNetwork/SwitchNetworkModal";
import TwoStepTransactionModal from "./TwoStepTransactionModal/TwoStepTransactionModal";
import FetchingWalletDataError from "./FetchingWalletDataError/FetchingWalletDataError";
import useNetworkStatus from "@hooks/useNetworkStatus";
import HowToBuyModal from "./HowToBuyModal/HowToBuyModal";
import { useModalsState } from "@contexts/ModalsStateContext";

const PresaleModals = () => {
  const { status, ethereum } = useMetaMask();
  const { processProcessing, transactionStatus } = useTransactionProcess();
  const { errorMessage } = useErrorModalContext();
  const isOnline = useNetworkStatus();
  const { howToBuyOpened, connectMetamaskOpened, switchNetworkOpened, closeConnectMetamask, fetchingDataErrorOpened } =
    useModalsState();

  const metamaskNotConnected = status === "notConnected";

  React.useEffect(() => {
    let interval: NodeJS.Timer;

    if (metamaskNotConnected) {
      interval = setInterval(() => {
        ethereum
          .request({ method: "eth_accounts" })
          .then((info: any[]) => info.length > 0 && closeConnectMetamask())
          .catch(console.error);
      }, 3000);
    }
    return () => {
      if (!interval) return;

      return clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (isOnline) {
    if (connectMetamaskOpened) {
      return <ConnectMetamaskModal />;
    }

    if (switchNetworkOpened) {
      return <SwitchNetworkModal />;
    }

    if (errorMessage) {
      return <SomethingWentWrong />;
    }

    if (fetchingDataErrorOpened) {
      return <FetchingWalletDataError />;
    }

    if (processProcessing) {
      return <TwoStepTransactionModal />;
    }

    if (transactionStatus === "done") {
      return <DoneTransactionModal />;
    }

    if (howToBuyOpened) {
      return <HowToBuyModal />;
    }
  }

  return <></>;
};

export default PresaleModals;
