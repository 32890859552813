import React from "react";

const useCaptcha = () => {
  const [captchaToken, setCaptchaToken] = React.useState<string | null>(null);
  const handleCaptcha = (token: string | null) => setCaptchaToken(token);

  return { captchaToken, handleCaptcha };
};

export default useCaptcha;
