import React from "react";
import { NotificationType } from "@contexts/NotificationContext";
import Stack from "@components/arrangement/Stack/Stack";
import Portal from "../Portal/Portal";
import "./Notification.scss";

interface NotificationProps extends NotificationType {
  close: () => void;
}

const Notification = ({ variant, title, message, close }: NotificationProps) => {
  const notificationClass = `notification slide-left notification-${variant}`;

  return (
    <Portal>
      <div className={notificationClass}>
        <button className="notification-close-button" onClick={close}>
          <img src="/images/close-icon.svg" alt="close button" />
        </button>
        <Stack justifyContent="flex-start" alignItems="flex-start">
          <p className="heading white">{title}</p>
          <p className="p3 white-2 mt-1">{message}</p>
        </Stack>
      </div>
    </Portal>
  );
};

export default Notification;
