export const numberToLocaleString = (
  value: number,
  locales: string | undefined = undefined,
  minimumFractionDigits: number = 0,
  maximumFractionDigits: number = 2
) =>
  value.toLocaleString(locales, {
    minimumFractionDigits,
    maximumFractionDigits
  });
