import React from "react";
import { ethereum } from "@services/ethereumInstance";
import { SESSION_STORAGE_ACCESS_CODE, writeRecordSessionStorage } from "src/utils/sessionStorage/sessionStorage";
import { paperTrailClientInstance } from "@services/papertrailInstance";

const useValidateAccessCode = (accessCode: string) => {
  const [verifyingAccessCode, setVerifyingAccessCode] = React.useState(false);
  const [accessCodeValid, setAccessCodeValid] = React.useState<boolean | undefined>(undefined);

  const startLoading = () => setVerifyingAccessCode(true);
  const stopLoading = () => setVerifyingAccessCode(false);

  const validateAccessCode = async () => {
    startLoading();
    try {
      const isValid = await ethereum.isAccessCodeValid(accessCode);
      setAccessCodeValid(isValid);
      if (isValid) {
        writeRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE, accessCode);
      }
    } catch (e: any) {
      setAccessCodeValid(false);
      const reqBody = (globalThis as any).loggerBody;
      paperTrailClientInstance.error(reqBody, e);
    } finally {
      stopLoading();
    }
  };

  React.useEffect(() => {
    setAccessCodeValid(undefined);
  }, [accessCode]);

  return { verifyingAccessCode, accessCodeValid, validateAccessCode };
};

export default useValidateAccessCode;
