import React from "react";
import Input from "@components/dataEntry/Input/Input";
import { useAccessCodeContext } from "@contexts/AccessCodeContext";
import "./AccessCodeInput.scss";

const AccessCodeInput = () => {
  const { accessCode, accessCodeValid, handleSetAccessCode } = useAccessCodeContext();

  const error = () => {
    if (accessCodeValid !== undefined && accessCodeValid === false) {
      return "This Access Code is incorrect. Try again.";
    }

    return "";
  };

  return (
    <Input
      value={accessCode}
      label="Access code"
      onChange={handleSetAccessCode}
      className="mb-7 widthLimit"
      type="text"
      placeholder="enter your Unique Access Code"
      error={error()}
    />
  );
};

export default AccessCodeInput;
