import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
// import { useAccessCodeContext } from "@contexts/AccessCodeContext";
import { paths } from "@data/paths";
import { Navigate } from "react-router-dom";
import { getRecordSessionStorage, SESSION_STORAGE_ACCESS_CODE } from "src/utils/sessionStorage/sessionStorage";
import { useAccessCodeContext } from "@contexts/AccessCodeContext";

const AccessPageGuard = ({ children }: IChildren) => {
  const { accessCodeValid } = useAccessCodeContext();
  const sessionStorageCode = getRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE);

  if (sessionStorageCode) {
    return <Navigate to={paths.presale} />;
  }

  if (accessCodeValid) {
    return <Navigate to={paths.presale} />;
  }

  return <>{children}</>;
};

export default AccessPageGuard;
