import React from "react";

export const usePromise = <T, Y extends Array<any>>(func: (...args: Y) => Promise<T>) => {
  const [resolved, setResolved] = React.useState<T | null>(null);
  const [rejected, setRejected] = React.useState<any>(null);
  const [waiting, setWaiting] = React.useState(false);

  const call = (...args: Y) => {
    reset();
    setWaiting(true);

    func(...args)
      .then(setResolved)
      .catch(setRejected)
      .finally(() => setWaiting(false));
  };

  const reset = () => {
    setResolved(null);
    setRejected(null);
    setWaiting(false);
  };

  return { resolved, rejected, waiting, call, reset };
};
