import { blockchainConfig } from "@config/config";
import { Ethereum } from "src/blockchain/Ethereum";

export const ethereum = new Ethereum({
  rpcUrl: blockchainConfig.BSC.rpcUrl,
  privateSaleAddress: blockchainConfig.PRIVATE_SALE.address,
  usdAddress: blockchainConfig.USD_TOKEN.address,
  tokenAddress: blockchainConfig.G2N_TOKEN.address,
  knownWhitelistedAddresses: [
    "0x741dBF0958E2baADCdF721a2286565EE0Fec836D",
    "0xa52308601D3c62F9490df976c86A90dE938EF5c7",
    "0x4ff606C678986D2F7e3d7424d4fBE2de3eeAfA6e",
    "0x9b4679fA537cb7A652Cd67C775388e34B5Aa7C6a"
  ]
});
