import React from "react";
import Layout from "../Layout";
import { IChildren } from "@components/interfaces/IChildren";
import Navbar from "@components/layoutElements/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import { ContractDataProvider } from "@contexts/ContractDataContext";
import { WalletDataProvider } from "@contexts/WalletDataContext";

const Layout1 = ({ children }: IChildren) => {
  return (
    <ContractDataProvider>
      <WalletDataProvider>
        <Layout variant={"navbar-content-footer"} Navbar={Navbar} Footer={Footer}>
          {children}
        </Layout>
      </WalletDataProvider>
    </ContractDataProvider>
  );
};

export default Layout1;
