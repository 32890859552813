import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import "./Modal.scss";

const Modal = ({ children }: IChildren) => {
  React.useEffect(() => {
    const body = document.body;
    if (!body) return;

    body.style.overflow = "hidden";

    return () => {
      body.style.overflow = "unset";
    };
  }, []);

  return <div className="modal">{children}</div>;
};

export default Modal;
