import Stack from "@components/arrangement/Stack/Stack";
import React from "react";
import "./DiscountPercent.scss";

const DiscountPercent = ({ value, variant }: { value: number; variant: "primary" | "secondary" }) => {
  return (
    <div className={`discount-percent ${variant}`}>
      <div className={`triangle ${variant}`} />
      <Stack justifyContent="flex-start" alignItems="flex-end" className="values" maxContent>
        <h5 className="primary">{value}%</h5>
        <h6 className="primary">OFF</h6>
      </Stack>
    </div>
  );
};

export default DiscountPercent;
