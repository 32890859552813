import axios, { Axios } from "axios";

export interface BscScanProxyClientOptions {
  url: string;
}

export interface BscScanTx {
  txHash: string;
  blockNumber: number;
  func: string;
  value: string;
  timestamp: number;
  url: string;
  from: string;
  accessCodeAddress: string;
}

export class BscScanProxyClient {
  private axios: Axios;

  constructor(options: BscScanProxyClientOptions) {
    this.axios = axios.create({
      baseURL: options.url
    });
  }

  async getTransactionsForUser(address: string) {
    const res = await this.axios.get<BscScanTx[]>("", {
      params: {
        address
      }
    });

    return res.data;
  }

  async getTransactionsForAccessCode(address: string) {
    const res = await this.axios.get<BscScanTx[]>("", {
      params: {
        address,
        addressType: "access"
      }
    });

    return res.data;
  }
}
