import React from "react";
import { ethereum } from "@services/ethereumInstance";
import { Ethereum } from "../blockchain/Ethereum";
import { paperTrailClientInstance } from "@services/papertrailInstance";
import { convertBigNumberToNumber } from "src/utils/convertBigNumberToNumber";

export type FetchStatus = "loading" | "success" | "failed";

export interface SaleData {
  discountPercent: number;
  basicPrice: number;
  discountPrice: number;
  busdCost: number;
  g2nToGet: number;
}

export interface ContractData {
  status: FetchStatus;
  saleData: SaleData[];
  error: Error | null;
}

const initialSaleData = (): SaleData[] => [
  { discountPercent: 0, basicPrice: 0, discountPrice: 0, busdCost: 0, g2nToGet: 0 }
];

const initialContractData = (): ContractData => ({
  status: "loading",
  saleData: initialSaleData(),
  error: null
});

const ContractDataContext = React.createContext(initialContractData());

export const fetchSaleData = async (ethereum: Ethereum): Promise<SaleData[]> => {
  const [cardsData, saleData] = await Promise.all([ethereum.getCardsData(), ethereum.getSaleData()]);

  const presaleData = cardsData.map(({ percent, price, tokenValue, usdValue }) => {
    return {
      discountPercent: percent.toNumber() / 100,
      basicPrice: convertBigNumberToNumber(saleData.price, 18, false),
      discountPrice: convertBigNumberToNumber(price, 18, false),
      busdCost: convertBigNumberToNumber(usdValue),
      g2nToGet: convertBigNumberToNumber(tokenValue, 4)
    };
  });

  return presaleData;
};

export const ContractDataProvider = ({ children }: React.PropsWithChildren) => {
  const [contractData, setContractData] = React.useState(initialContractData());

  React.useEffect(() => {
    fetchContractData();
  }, []);

  const fetchContractData = async () => {
    setContractData(initialContractData());
    const reqBody = (globalThis as any).loggerBody;

    try {
      paperTrailClientInstance.info(reqBody, `Fetching contract data`);
      const saleData = await fetchSaleData(ethereum);
      setContractData((d) => ({ ...d, saleData, status: "success" }));
    } catch (e: any) {
      paperTrailClientInstance.error(reqBody, `Couldn't get contract data, ${e}`);
      setContractData((d) => ({ ...d, status: "failed", error: e }));
    }
  };

  return <ContractDataContext.Provider value={contractData}>{children}</ContractDataContext.Provider>;
};

export const useContractData = () => React.useContext(ContractDataContext);
