import Group from "@components/arrangement/Group/Group";
import { useTransactionProcess } from "@contexts/TransactionProcessContext";
import React from "react";
import Divider from "./Divider/Divider";
import Step from "./TransactionStep/Step";
import { steps } from "./TransactionStep/steps";
import "./TransactionStepper.scss";

const TransactionStepper = () => {
  const { transactionStatus } = useTransactionProcess();
  const { stepOne, stepTwo } = steps;
  return (
    <Group className="transaction-stepper">
      <Step
        active={transactionStatus === "firstStepActive"}
        loading={transactionStatus === "firstStepProcessing"}
        stepNumber={stepOne.stepNumber}
        title={stepOne.title}
        description={stepOne.description}
      />
      <Divider highlighted={transactionStatus === "secondStepActive"} />
      <Step
        active={transactionStatus === "secondStepActive"}
        loading={transactionStatus === "secondStepProcessing"}
        stepNumber={stepTwo.stepNumber}
        title={stepTwo.title}
        description={stepTwo.description}
      />
    </Group>
  );
};

export default TransactionStepper;
