import React from "react";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Button from "@components/dataEntry/Buttons/Button/Button";
import { METAMASK_URL } from "@data/constants";
import "./UnsupportedBrowser.scss";

const UnsupportedBrowser = () => {
  const goToMetaMask = () => {
    window.open(METAMASK_URL, "_self");
  };
  return (
    <Backdrop open>
      <Portal>
        <Modal>
          <Stack className="unsupported-browser" alignItems="center">
            <h5 className="mb-2 white">Your browser is not supported by MetaMask</h5>
            <p className="white-2 mb-6 p0">List of supported browsers:</p>
            <Group wrap justifyContent="center" className="icons-wrapper mb-6">
              <Stack alignItems="center" className="xs-2">
                <img src="/images/chrome.png" alt="browser" className="mb-1" />
                <p className="white-1">Chrome</p>
              </Stack>
              <Stack className="xs-2" alignItems="center">
                <img src="/images/firefox.png" alt="browser" className="mb-1" />
                <p className="white-1">Firefox</p>
              </Stack>
              <Stack className="xs-2" alignItems="center">
                <img src="/images/brave.png" alt="browser" className="mb-1" />
                <p className="white-1">Brave</p>
              </Stack>
              <Stack className="xs-2" alignItems="center">
                <img src="/images/opera.png" alt="browser" className="mb-1" />
                <p className="white-1">Opera</p>
              </Stack>
              <Stack className="xs-2" alignItems="center">
                <img src="/images/edge.png" alt="browser" className="mb-1" />
                <p className="white-1">Edge</p>
              </Stack>
            </Group>
            <Button variant="primary" onClick={goToMetaMask}>
              Go to metamask
            </Button>
          </Stack>
        </Modal>
      </Portal>
    </Backdrop>
  );
};

export default UnsupportedBrowser;
