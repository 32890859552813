import React from "react";
import { Router } from "@features/router/router";
import MobileGuard from "@pages/Presale/Guards/MobileGuard";
import BrowserGuard from "@pages/Presale/Guards/BrowserGuard";

const App = () => {
  return (
    <MobileGuard>
      <BrowserGuard>
        <Router />
      </BrowserGuard>
    </MobileGuard>
  );
};

export default App;
