import React from "react";
import { useWalletData } from "@contexts/WalletDataContext";
import { numberToLocaleString } from "src/utils/numberToLocaleString";
import GoldenCard from "./GoldenCard/GoldenCard";
import InvestedCard from "./InvestedCard/InvestedCard";
import { convertBigNumberToNumber } from "src/utils/convertBigNumberToNumber";

const GoldenCardContainer = () => {
  const walletData = useWalletData();
  const loadingWalletData = walletData.status === "loading";

  const receivedBalance = convertBigNumberToNumber(walletData.balances.contractMaxBalance, 4);
  const formattedReceivedBalance = numberToLocaleString(receivedBalance);

  const investedBalance = convertBigNumberToNumber(walletData.balances.usdPaid, 18);
  const formattedInvestedBalance = numberToLocaleString(investedBalance);

  return (
    <GoldenCard receivedBalance={formattedReceivedBalance} receivedCurrency="G2N" loadingData={loadingWalletData}>
      <InvestedCard
        investedBalance={formattedInvestedBalance}
        investedCurrency="BUSD"
        loadingData={loadingWalletData}
      />
    </GoldenCard>
  );
};
export default GoldenCardContainer;
