import { blockchainConfig } from "@config/config";

const buildNetworkParams = () => {
  return {
    chainId: blockchainConfig.BSC.chainId,
    chainName: blockchainConfig.BSC.name,
    rpcUrls: [blockchainConfig.BSC.rpcUrl],
    nativeCurrency: {
      name: blockchainConfig.BSC.nativeToken,
      symbol: blockchainConfig.BSC.nativeToken,
      decimals: blockchainConfig.BSC.nativeDecimals
    },
    blockExplorerUrls: [blockchainConfig.BSC.scannerUrl]
  };
};

export const currentNetworkParams = buildNetworkParams();
