export const steps = {
  stepOne: {
    stepNumber: 1,
    title: "Give permission",
    description: "Accept a metamask prompt window to allow spending assets on behalf of your account"
  },
  stepTwo: {
    stepNumber: 2,
    title: "Confirm Transaction",
    description: "Execution of the target transaction"
  }
};
