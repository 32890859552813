import React from "react";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Portal from "@components/overlay/Portal/Portal";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import OfflineIcon from "./OfflineIcon";
import "./OfflineNetwork.scss";

const OfflineNetwork = () => {
  return (
    <Backdrop open>
      <Portal>
        <Stack justifyContent="center" alignItems="center">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="offline-network-modal" alignItems="center">
                <OfflineIcon className="mb-3" />
                <h6 className="title white mb-2">It seems that you're offline</h6>
                <p className="white">Please check your connection and try again.</p>
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default OfflineNetwork;
