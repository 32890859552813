import React from "react";
import useNetworkStatus from "@hooks/useNetworkStatus";
import OfflineNetwork from "@pages/shared-components/Modals/OfflineNetwork/OfflineNetwork";

const GlobalModals = () => {
  const isOnline = useNetworkStatus();

  if (!isOnline) {
    return <OfflineNetwork />;
  }

  return <></>;
};

export default GlobalModals;
