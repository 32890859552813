import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import { useTransactionProcess } from "@contexts/TransactionProcessContext";
import { blockchainConfig } from "@config/config";
import { useOrderHistory } from "@contexts/OrderHistoryContext";
import "./DoneTransactionModal.scss";

const scrollToTransactions = () => {
  const access = document.getElementById("transactions");
  if (!access) return;

  access.scrollIntoView({ behavior: "smooth" });
};

const DoneTransactionModal = () => {
  const { txHash, resetTransaction } = useTransactionProcess();
  const { setNewTransactionHighlight } = useOrderHistory();
  const txHashUrl = `${blockchainConfig.BSC.scannerUrl}/tx/${txHash}`;

  const handleCloseModal = () => {
    resetTransaction();
    scrollToTransactions();
    setNewTransactionHighlight();
  };

  return (
    <Backdrop open onClick={handleCloseModal}>
      <Portal>
        <Stack justifyContent="center" alignItems="center" className="done-transaction-modal">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <button onClick={handleCloseModal} className="close-button">
                <img src="/images/close-icon.svg" alt="close button" />
              </button>
              <Stack className="done-modal" alignItems="center">
                <img
                  src="/images/success-icon.svg"
                  alt="success icon"
                  className="mb-4 success"
                  width={102}
                  height={102}
                />
                <h6 className="title white mb-6">Your transaction has been successfully completed</h6>
                <p className="p1 white-0 mb-2">Your transaction's ID</p>
                <p className="p0 white mb-2">{txHash}</p>
                <a
                  className="primary uppercase regular p1 hover"
                  href={txHashUrl}
                  rel="noreferrer noopener nofollow"
                  target="_blank"
                >
                  View on BscScan &gt;
                </a>
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default DoneTransactionModal;
