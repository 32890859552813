import React from "react";
import { NOTIFICATION_CLEAR_TIME } from "@config/config";

export type NotificationVariant = "success" | "error";

export interface NotificationType {
  variant: NotificationVariant;
  title: string;
  message: string;
}

interface IContext {
  children: React.ReactNode;
}

const NotificationContext = React.createContext(null as any);

export const NotificationProvider = ({ children }: IContext) => {
  const [notification, setNotification] = React.useState<NotificationType | null>(null);

  const addNotification = (variant: NotificationVariant, title: string, message: string) =>
    setNotification({ variant, title, message });

  const removeNotification = () => setNotification(null);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (notification) {
        removeNotification();
      }
    }, NOTIFICATION_CLEAR_TIME);

    return () => {
      clearInterval(interval);
    };
  }, [notification]);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        addNotification,
        removeNotification
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => React.useContext(NotificationContext);
