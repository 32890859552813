import React from "react";

const MESSAGE_FIELD = {
  label: "Your message",
  placeholder: "Message content",
  type: "textarea"
};

const EMPTY_MESSAGE_ERROR = "Message cannot be empty.";

const useFormMessage = () => {
  const [message, setMessage] = React.useState({
    value: "",
    error: ""
  });

  const handleSetMessage = (message: string) => {
    if (message.length === 0) {
      setMessage((prev) => ({ ...prev, value: message, error: EMPTY_MESSAGE_ERROR }));
      return;
    }

    setMessage((prev) => ({ ...prev, value: message, error: "" }));
  };

  const resetMessage = () => {
    setMessage({
      value: "",
      error: ""
    });
  };

  return { message, MESSAGE_FIELD, handleSetMessage, resetMessage };
};

export default useFormMessage;
