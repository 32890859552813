const USD_TOKEN_ADDRESS_ENV = process.env.REACT_APP_USD_ADDR || null;
const G2N_TOKEN_ADDRESS_ENV = process.env.REACT_APP_G2N_ADDR || null;
const PRIVATE_SALE_ADDESS_ENV = process.env.REACT_APP_PRIVATE_SALE_ADDR || null;

const getUsdTokenAddress = () => {
  if (USD_TOKEN_ADDRESS_ENV) return USD_TOKEN_ADDRESS_ENV;
  throw new Error("There is no provided such env variable");
};
const getG2nTokenAddress = () => {
  if (G2N_TOKEN_ADDRESS_ENV) return G2N_TOKEN_ADDRESS_ENV;
  throw new Error("There is no provided such env variable");
};
const getPrivateSaleAddress = () => {
  if (PRIVATE_SALE_ADDESS_ENV) return PRIVATE_SALE_ADDESS_ENV;
  throw new Error("There is no provided such env variable");
};

export const USD_TOKEN_ADDRESS = getUsdTokenAddress();
export const G2N_TOKEN_ADDRESS = getG2nTokenAddress();
export const PRIVATE_SALE_ADDRESS = getPrivateSaleAddress();

export type BlockchainConfig = {
  USD_TOKEN: { address: string; decimals: number };
  G2N_TOKEN: { address: string; decimals: number };
  PRIVATE_SALE: { address: string };
  BSC: {
    rpcUrl: string;
    chainId: string;
    name: string;
    scannerUrl: string;
    nativeToken: string;
    nativeDecimals: number;
    stableCoin: string;
  };
};

export const testnet: BlockchainConfig = {
  USD_TOKEN: {
    address: USD_TOKEN_ADDRESS,
    decimals: 18
  },
  G2N_TOKEN: {
    address: G2N_TOKEN_ADDRESS,
    decimals: 4
  },
  PRIVATE_SALE: {
    address: PRIVATE_SALE_ADDRESS
  },
  BSC: {
    rpcUrl: "https://data-seed-prebsc-1-s3.binance.org:8545/",
    chainId: "0x61",
    name: "BSC Testnet",
    scannerUrl: "https://testnet.bscscan.com",
    nativeToken: "BNB",
    nativeDecimals: 18,
    stableCoin: "BUSD"
  }
};

export const mainnet: BlockchainConfig = {
  USD_TOKEN: {
    address: USD_TOKEN_ADDRESS,
    decimals: 18
  },
  G2N_TOKEN: {
    address: G2N_TOKEN_ADDRESS,
    decimals: 4
  },
  PRIVATE_SALE: {
    address: PRIVATE_SALE_ADDRESS
  },
  BSC: {
    rpcUrl: "https://bsc-dataseed1.binance.org",
    chainId: "0x38",
    name: "Binance Smart Chain Mainnet",
    scannerUrl: "https://bscscan.com",
    nativeToken: "BNB",
    nativeDecimals: 18,
    stableCoin: "BUSD"
  }
};
