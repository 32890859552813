import React from "react";

const useNetworkStatus = () => {
  const [isOnline, setNetworkStatus] = React.useState<boolean>(window.navigator.onLine);

  const updateNetworkStatus = () => setNetworkStatus(window.navigator.onLine);

  React.useEffect(() => {
    window.addEventListener("offline", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);

    return () => {
      window.removeEventListener("offline", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
    };
  });

  return isOnline;
};

export default useNetworkStatus;
