import React from "react";
import { useOrderHistory } from "./OrderHistoryContext";
import { useWalletData } from "./WalletDataContext";

interface IContext {
  children: React.ReactNode;
}

type DataVariants = "sig" | "hash" | "txHash" | string;

interface TransactionData {
  data: Record<DataVariants, any>;
  name: string;
}

type AllowedTransactionSteps =
  | "neutral"
  | "firstStepActive"
  | "firstStepProcessing"
  | "secondStepActive"
  | "secondStepProcessing"
  | "done"
  | "fail";

const TransactionProcessContext = React.createContext(null as any);

export const TransactionProcessProvider = ({ children }: IContext) => {
  const [transactionStatus, setTransactionStatus] = React.useState<AllowedTransactionSteps>("firstStepActive");

  const [processProcessing, setProcessProcessing] = React.useState(false);
  const [currentAllowance, setCurrentAllowance] = React.useState(0);
  const [currentG2NTokens, setCurrentG2NTokens] = React.useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastAllowance, setLastAllowance] = React.useState(0);
  const [txHash, setTxHash] = React.useState("");
  const { refetch } = useWalletData();
  const { addNewTransaction } = useOrderHistory();

  const activeFirstStep = () => setTransactionStatus("firstStepActive");
  const processFirstStep = () => setTransactionStatus("firstStepProcessing");

  const activeSecondStep = () => setTransactionStatus("secondStepActive");
  const processSecondStep = () => setTransactionStatus("secondStepProcessing");
  const doneTransaction = () => setTransactionStatus("done");
  const failTransaction = () => setTransactionStatus("fail");
  const resetTransaction = () => setTransactionStatus("firstStepActive");

  const startProcessing = () => setProcessProcessing(true);
  const stopProcessing = () => setProcessProcessing(false);

  const updateAllowance = (allowance: number, tokens: number) => {
    setLastAllowance(currentAllowance);
    setCurrentAllowance(allowance);
    setCurrentG2NTokens(tokens);
  };
  const resetAllowance = () => {
    setLastAllowance(0);
    setCurrentAllowance(0);
    setCurrentG2NTokens(0);
  };

  const rejectIncreaseAllowance = () => {
    activeFirstStep();
  };

  const rejectTransaction = () => {
    activeSecondStep();
  };

  const updateTxHash = (txHash: string) => {
    setTxHash(txHash);
  };

  const messageActionDictionary = {
    currentAllowance: (d: Record<string, any>) => {},
    increaseAllowanceTx: (d: Record<string, any>) => activeFirstStep(),
    increaseAllowanceTxSent: (d: Record<string, any>) => processFirstStep(),
    increaseAllowanceTxConfirmed: (d: Record<string, any>) => {},
    buyTx: (d: Record<string, any>) => activeSecondStep(),
    buyTxSent: (d: Record<string, any>) => processSecondStep(),
    buyTxConfirmed: (d: Record<string, any>) => {
      doneTransaction();
      stopProcessing();
      refetch();
      addNewTransaction(d.receipt, d.args);
    }
  };

  type ActionKeys = keyof typeof messageActionDictionary;

  const getTransactionData = React.useCallback((transactionData: TransactionData) => {
    const name = transactionData.name as ActionKeys;

    const txHash = transactionData.data.txHash || null;

    if (txHash) {
      updateTxHash(txHash);
    }

    const fn = messageActionDictionary[name] || (() => {});
    fn(transactionData.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TransactionProcessContext.Provider
      value={{
        currentAllowance,
        currentG2NTokens,
        getTransactionData,
        failTransaction,
        resetTransaction,
        transactionStatus,
        processProcessing,
        startProcessing,
        stopProcessing,
        updateAllowance,
        resetAllowance,
        rejectTransaction,
        rejectIncreaseAllowance,
        txHash
      }}
    >
      {children}
    </TransactionProcessContext.Provider>
  );
};

export const useTransactionProcess = () => React.useContext(TransactionProcessContext);
