import Group from "@components/arrangement/Group/Group";
import Button from "@components/dataEntry/Buttons/Button/Button";
import React from "react";
import "./HowToBuyButton.scss";
import { useModalsState } from "@contexts/ModalsStateContext";

const HowToBuyButton = () => {
  const { openHowToBuy } = useModalsState();
  return (
    <Button variant="primary" onClick={openHowToBuy} className="how-to-buy-button">
      <Group justifyContent="center" alignItems="center" colGap={8}>
        <img src="/images/youtube-logo.svg" alt="youtube logo" className="youtube-logo" />
        How to buy
      </Group>
    </Button>
  );
};

export default HowToBuyButton;
