import React from "react";
import "./Divider.scss";

interface IDivider {
  highlighted: boolean;
}

const Divider = ({ highlighted }: IDivider) => {
  return <hr className={`divider${highlighted ? " active" : ""}`} />;
};

export default Divider;
