import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Modal from "@components/overlay/Modal/Modal";
import { METAMASK_URL } from "@data/constants";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import { useMetaMask } from "metamask-react";
import { useModalsState } from "@contexts/ModalsStateContext";
import "./ConnectMetamaskModal.scss";

const ConnectMetamaskModal = () => {
  const { status, connect } = useMetaMask();
  const { closeConnectMetamask } = useModalsState();

  const metamaskUnavailable = status === "unavailable";

  return (
    <Backdrop open onClick={closeConnectMetamask}>
      <Portal>
        <Stack justifyContent="center" alignItems="center">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="connect-metamask-modal" alignItems="center">
                <button onClick={closeConnectMetamask} className="close-icon">
                  <img src="/images/close-icon.svg" alt="close icon" />
                </button>
                <img src="/images/metamask-logo.svg" alt="metamask logo" className="mb-4 logo" width={94} height={88} />
                <h6 className="title white">Connect to Metamask</h6>
                <Button
                  className="mb-2"
                  onClick={connect}
                  loading={status === "connecting"}
                  variant="primary"
                  disabled={metamaskUnavailable}
                >
                  Connect metamask
                </Button>
                {metamaskUnavailable && (
                  <span className="p2 white-2">
                    Don't have MetaMask?{" "}
                    <a
                      href={METAMASK_URL}
                      rel="noopener noreferrer nofollow"
                      className="primary underline"
                      target="_blank"
                    >
                      Download here
                    </a>
                  </span>
                )}
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default ConnectMetamaskModal;
