export const SESSION_STORAGE_ACCESS_CODE =
  "m87FRH7n6f56dnf7gV7o66to8n9m)U0877m)Gfntcnrxb54eB(d976*m9hbvrtvE5689TNm8Y98";

export const writeRecordSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const getRecordSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const removeRecordSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};
