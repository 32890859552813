import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Group from "@components/arrangement/Group/Group";
import "./UnavailableOnMobileModal.scss";
import { copyUrl } from "src/utils/copyUrl";

const UnavailableOnMobileModal = () => {
  const currentUrl = window.location.href;

  return (
    <Backdrop open>
      <Portal>
        <Modal>
          <Stack className="unavailable-on-mobile" alignItems="center">
            <img src="/images/something-went-wrong.svg" alt="warning" className="mb-4 warning" />
            <h6 className="title white mb-2">App is unavailable on mobile devices</h6>
            <p className="p2 white-2 mb-5">Enter here on your desktop device</p>
            <Button onClick={() => copyUrl(currentUrl)} variant="primary">
              <Group colGap={8}>
                <span className="black">Copy current url</span>
                <img src="/images/copy-black.svg" alt="copy icon" />
              </Group>
            </Button>
          </Stack>
        </Modal>
      </Portal>
    </Backdrop>
  );
};

export default UnavailableOnMobileModal;
