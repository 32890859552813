import Stack from "@components/arrangement/Stack/Stack";
import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <Stack className="inner-footer" justifyContent="center">
        <img src="/images/go2nft-navigation-logo.svg" alt="go2nft logo" />
      </Stack>
    </div>
  );
};

export default Footer;
