import { AppStage, DEBUG_MODE } from "@config/config";
import { MetaMaskState } from "metamask-react/lib/metamask-context";
import { LoggerConfig } from "src/logger/LoggerContext";
import { Buffer } from "buffer";

export interface LogBody {
  userAgent?: string;
  walletAddress?: MetaMaskState["account"];
  metaMaskStatus?: MetaMaskState["status"];
  requiredNetwork?: string;
  usersCurrentNetworkChainId: string | null;
  transactionStatus?: string;
  bnbBalance?: string;
  busdBalance?: string;
  appStage?: AppStage;
  message?: string;
}

type Level = "info" | "error";

export class PapertrailClient {
  level: Level;
  constructor(private papertrailConfig: LoggerConfig) {
    this.level = "info";
  }

  private async sendRequest(config: LoggerConfig, body: LogBody, level: Level) {
    const serviceEnabled = config.enabled;

    const bodyToSend = JSON.stringify({ level, ...body });

    if (DEBUG_MODE) {
      console.log(bodyToSend);
    }
    if (!serviceEnabled) {
      return;
    }

    const url = `${config.host}/v1/log`;
    const tokenAsBuffer = Buffer.from(`:${config.token}`).toString("base64");
    try {
      await fetch(url, {
        headers: {
          "content-type": "text/plain",
          authorization: "Basic " + tokenAsBuffer
        },
        method: "POST",
        body: bodyToSend
      });
    } catch (e) {
      console.log("Papertrail request has failed", `Stack Trace: ${e}`);
    }
  }

  async info(body: LogBody, message?: string) {
    this.level = "info";
    const error = message || "For some reason Message was not provided";
    const requestBody = { message: error, ...body };
    await this.sendRequest(this.papertrailConfig, requestBody, "info");
  }
  async error(body: LogBody, error?: string) {
    this.level = "error";
    const errorToSend = error || "For some reason Stack Trace was not provided";
    const requestBody = { message: errorToSend, ...body };
    await this.sendRequest(this.papertrailConfig, requestBody, "error");
  }
}

//user entered using $1 code
//user connected to metamask
//user changed network to $1
//user tries to buy tokens for $1 BUSD
//error occured during transaction. Stack trace: $1
