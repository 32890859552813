import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
// import Button from "@components/dataEntry/Buttons/Button/Button";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
// import { useWalletData } from "@contexts/WalletDataContext";
import { blockchainConfig } from "@config/config";
import "./FetchingWalletDataError.scss";
import { useModalsState } from "@contexts/ModalsStateContext";

const FetchingWalletDataError = () => {
  const { closeFetchingDataError } = useModalsState();

  return (
    <Backdrop open onClick={closeFetchingDataError}>
      <Portal>
        <Stack justifyContent="center" alignItems="center">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="fetching-error-modal" alignItems="center">
                <button className="top-right-corner" onClick={closeFetchingDataError}>
                  <img src="/images/close-icon.svg" alt="close icon" />
                </button>
                <img
                  src="/images/warning-exclamation-mark.svg"
                  alt="warning exclamation mark"
                  className="mb-3"
                  height={105}
                  width={108}
                />
                <h6 className="title white mb-2">{blockchainConfig.BSC.name} error</h6>
                <p className="p0 warning-color warning-text">
                  Server is not responding. Refresh the page or try again later
                </p>
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default FetchingWalletDataError;
