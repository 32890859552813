import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { IChildren } from "@components/interfaces/IChildren";
import {
  getRecordSessionStorage,
  removeRecordSessionStorage,
  SESSION_STORAGE_ACCESS_CODE
} from "src/utils/sessionStorage/sessionStorage";
import useValidateAccessCode from "@hooks/useValidateAccessCode";
import ValidatingAccessCode from "@pages/Presale/Modals/ValidatingAccessCode/ValidatingAccessCode";
import { paths } from "@data/paths";
import { LogBody } from "@services/PapertrailClient";
import { paperTrailClientInstance } from "@services/papertrailInstance";

export const ACCESS_CODE_PARAM = "access";

const AccessCodeGuard = ({ children }: IChildren) => {
  const [haveChecked, setHaveChecked] = React.useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramsAccessCode = params.get(ACCESS_CODE_PARAM);
  const sessionStorageAccessCode = getRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE);
  const reqBody = (globalThis as any).loggerBody as LogBody;

  const getAccessCodeToValidate = () => {
    if (!paramsAccessCode && !sessionStorageAccessCode) {
      return "";
    }
    if (sessionStorageAccessCode && !paramsAccessCode) {
      return sessionStorageAccessCode;
    }
    if (paramsAccessCode && !sessionStorageAccessCode) {
      return paramsAccessCode;
    }
    if (sessionStorageAccessCode && paramsAccessCode && sessionStorageAccessCode !== paramsAccessCode) {
      return paramsAccessCode;
    }
    if (sessionStorageAccessCode && paramsAccessCode && sessionStorageAccessCode === paramsAccessCode) {
      return sessionStorageAccessCode;
    }
    throw new Error("unhandled condition");
  };
  const accessCodeToValidate = getAccessCodeToValidate();

  const { verifyingAccessCode, accessCodeValid, validateAccessCode } = useValidateAccessCode(accessCodeToValidate);

  React.useEffect(() => {
    if (sessionStorageAccessCode && !paramsAccessCode) {
      return;
    }
    if (sessionStorageAccessCode && paramsAccessCode && sessionStorageAccessCode === paramsAccessCode) {
      return;
    }

    setHaveChecked(true);
    validateAccessCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorageAccessCode, paramsAccessCode]);

  if (!sessionStorageAccessCode && !paramsAccessCode) {
    return <Navigate to={paths.accessPage} />;
  }

  if (sessionStorageAccessCode && !paramsAccessCode) {
    paperTrailClientInstance.info(reqBody, `User entered Presale with accessCode: ${sessionStorageAccessCode}`);
    return <>{children}</>;
  }

  if (
    sessionStorageAccessCode &&
    paramsAccessCode &&
    sessionStorageAccessCode === paramsAccessCode &&
    !verifyingAccessCode &&
    accessCodeValid
  ) {
    paperTrailClientInstance.info(reqBody, `User entered Presale using prepared url: ${window.location.href}`);
    return <>{children}</>;
  }

  if (verifyingAccessCode) {
    paperTrailClientInstance.info(reqBody, `User tries to enter Presale using url: ${window.location.href}`);
    return <ValidatingAccessCode />;
  }

  if (sessionStorageAccessCode && paramsAccessCode && sessionStorageAccessCode !== paramsAccessCode) {
    removeRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE);
    return <Navigate to={paths.accessPage} />;
  }

  if (paramsAccessCode && !sessionStorageAccessCode && !haveChecked) {
    return <ValidatingAccessCode />;
  }

  if (accessCodeValid) {
    return <>{children}</>;
  }

  return <Navigate to={paths.accessPage} />;
};

export default AccessCodeGuard;
