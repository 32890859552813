import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMetaMask } from "metamask-react";

import Stack from "@components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Buttons/Button/Button";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Group from "@components/arrangement/Group/Group";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import Input from "@components/dataEntry/Input/Input";
import TextArea from "@components/dataEntry/TextArea/TextArea";

import useFormEmail from "@hooks/useFormEmail";
import useFormProblemMessage from "@hooks/useFormProblemMessage";
import useFetch from "@hooks/useFetch";
import useCaptcha from "@hooks/useCaptcha";
import { useErrorModalContext } from "@contexts/ErrorModalContext";
import { useNotificationContext } from "@contexts/NotificationContext";
import { emailService } from "@services/emailServiceInstance";
import { appStage, MAILING_CAPTCHA_KEY } from "@config/config";
import { getRecordSessionStorage, SESSION_STORAGE_ACCESS_CODE } from "src/utils/sessionStorage/sessionStorage";

import "./SomethingWentWrong.scss";
import { EmailBody } from "@services/EmailService";

const SomethingWentWrong = () => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const { email, EMAIL_FIELD, handleSetEmail, resetEmail } = useFormEmail();
  const { problemMessage, PROBLEM_MESSAGE_FIELD, handleSetProblemMessage, resetProblemMessage } =
    useFormProblemMessage();
  const { addNotification } = useNotificationContext();
  const { captchaToken, handleCaptcha } = useCaptcha();
  const { account: accountAddress } = useMetaMask();
  const { errorMessage, closeErrorModal } = useErrorModalContext();

  const accessCode = getRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE);

  const emailBody: EmailBody = {
    email: email.value,
    message: problemMessage,
    address: accountAddress ?? "",
    accessToken: accessCode ?? "",
    captchaToken: captchaToken ?? "",
    error: errorMessage
  };

  const { doFetch, isLoading, data, error } = useFetch(async () => emailService.problemEmail(emailBody));

  const correctEmailValue = Boolean(email.error) || !Boolean(email.value);
  const sendButtonDisabledDev = isLoading || correctEmailValue;
  const sendButtonDisabledProd = !Boolean(captchaToken) || sendButtonDisabledDev;

  const sendButtonDisabled = appStage === "dev" ? sendButtonDisabledDev : sendButtonDisabledProd;

  const resetCaptcha = () => {
    const recaptcha = recaptchaRef.current;
    if (!recaptcha) return;
    recaptcha.reset();
  };

  const resetForm = React.useCallback(() => {
    resetEmail();
    resetProblemMessage();
    resetCaptcha();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!data) return;
    const status = data.ok;

    if (status) {
      addNotification("success", "Thank you", "Your message has been sent.");
      resetForm();
      closeErrorModal();
      return;
    }
    if (!status || error) {
      addNotification("error", "Failed to send message.", "There was an error, please try again later.");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, resetForm]);

  return (
    <Backdrop open onClick={closeErrorModal}>
      <Portal>
        <Stack justifyContent="center" alignItems="center" className="something-went-wrong-modal">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="modal-content-wrapper" alignItems="center" justifyContent="center">
                <button className="something-went-wrong-modal-close-button" onClick={closeErrorModal}>
                  <img src="/images/close-icon.svg" alt="close button" />
                </button>
                <img
                  src="/images/something-went-wrong.svg"
                  alt="warning"
                  className="mb-3 warning"
                  width={97}
                  height={78}
                />
                <h6 className="title white ">Oops... Something went wrong.</h6>
                <p className="white-0 p1 mb-3 description">
                  Sorry, the transaction was not successful. Please provide a detailed description of the event, thanks
                  to which we will be able to solve your problem.
                </p>
                <Input
                  value={email.value}
                  error={email.error}
                  label={EMAIL_FIELD.label}
                  placeholder={EMAIL_FIELD.placeholder}
                  onChange={handleSetEmail}
                  className="mb-6"
                  darkBackground
                  type="text"
                />
                <TextArea
                  value={problemMessage}
                  onChange={handleSetProblemMessage}
                  placeholder={PROBLEM_MESSAGE_FIELD.placeholder}
                  label={PROBLEM_MESSAGE_FIELD.label}
                  className="text-area mb-5"
                  darkBackground
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={MAILING_CAPTCHA_KEY}
                  onChange={(token) => handleCaptcha(token)}
                  theme="dark"
                />
                <Button
                  variant="primary"
                  disabled={sendButtonDisabled}
                  loading={isLoading}
                  onClick={() => doFetch(`User sends email due to error in app: ${JSON.stringify(emailBody)}`)}
                  className="mt-2"
                >
                  <Group colGap={8}>
                    Send message
                    {!isLoading && <img src="/images/send.svg" alt="send icon" />}
                  </Group>
                </Button>
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default SomethingWentWrong;
