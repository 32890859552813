import React from "react";

interface IContext {
  children: React.ReactNode;
}

const ModalsStateContext = React.createContext(null as any);

export const ModalsStateProvider = ({ children }: IContext) => {
  const [howToBuyOpened, setHowToBuyOpened] = React.useState(false);
  const openHowToBuy = () => setHowToBuyOpened(true);
  const closeHowToBuy = () => setHowToBuyOpened(false);

  const [connectMetamaskOpened, setConnectMetamaskOpened] = React.useState(false);
  const openConnectMetamask = () => setConnectMetamaskOpened(true);
  const closeConnectMetamask = () => setConnectMetamaskOpened(false);

  const [switchNetworkOpened, setSwitchNetworkOpened] = React.useState(false);
  const openSwitchNetwork = () => setSwitchNetworkOpened(true);
  const closeSwitchNetwork = () => setSwitchNetworkOpened(false);

  const [switchingNetwork, setSwitchingNetwork] = React.useState(false);
  const startSwitchingNetwork = () => setSwitchingNetwork(true);
  const stopSwitchingNetwork = () => setSwitchingNetwork(false);

  const [fetchingDataErrorOpened, setFetchingDataErrorOpened] = React.useState(false);
  const openFetchingDataError = () => setFetchingDataErrorOpened(true);
  const closeFetchingDataError = () => setFetchingDataErrorOpened(false);

  return (
    <ModalsStateContext.Provider
      value={{
        howToBuyOpened,
        openHowToBuy,
        closeHowToBuy,
        connectMetamaskOpened,
        openConnectMetamask,
        closeConnectMetamask,
        switchNetworkOpened,
        openSwitchNetwork,
        closeSwitchNetwork,
        switchingNetwork,
        startSwitchingNetwork,
        stopSwitchingNetwork,
        fetchingDataErrorOpened,
        openFetchingDataError,
        closeFetchingDataError
      }}
    >
      {children}
    </ModalsStateContext.Provider>
  );
};

export const useModalsState = () => React.useContext(ModalsStateContext);
