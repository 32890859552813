import React from "react";
import Group from "@components/arrangement/Group/Group";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import { HERO_YOUTUBE_URL } from "@data/constants";
import "./Hero.scss";
import HowToBuyButton from "./HowToBuyButton/HowToBuyButton";

const Hero = () => {
  return (
    <div className="hero">
      <h2 className="white relative">Private Sale</h2>
      <Group alignItems="center">
        <img src="/images/big-flare.svg" alt="flare" className="flare" />
        <div className="xs-6">
          <h5 className="white mb-6 head-description">
            One step ahead of others! Get to know our private sale plans and invest now!
          </h5>
          <HowToBuyButton />
        </div>
        <div className="xs-6">
          <GradientBorder radius={12} withBoxShadow={true} gradient={6}>
            <div style={{ width: "100%", height: "100%", padding: "20px" }}>
              <iframe
                width="100%"
                height="278px"
                src={HERO_YOUTUBE_URL}
                title="YouTube video player"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="mb-3 video"
              ></iframe>
              <p className="p1 white-2 mb-3">
                Check out the materials and take advantage of the knowledge of the exclusive private sale now!
              </p>
              {/* <Group justifyContent="flex-end">
                <LinkButton url="/" variant="secondary" target="_blank" download>
                <Group colGap={16}>
                    <span>Download document</span>
                    <img src="/images/download-icon.svg" alt="download" />
                    </Group>
                    </LinkButton>
                  </Group> */}
            </div>
          </GradientBorder>
        </div>
      </Group>
    </div>
  );
};

export default Hero;
