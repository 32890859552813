import React from "react";
import TxListAdmin from "./TxListAdmin";
import { BscScanProxyClient } from "../../blockchain/BscScanProxyClient";
import { BSC_SCAN_PROXY_URL } from "@config/config";
import "./AdminPage.scss";

const bscScan = new BscScanProxyClient({ url: BSC_SCAN_PROXY_URL });

const AdminPage = () => {
  return (
    <div className="admin-page">
      <h3 style={{ color: "white" }}>Admin Page</h3>
      <TxListAdmin bscScan={bscScan} />
    </div>
  );
};

export default AdminPage;
