import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import Modal from "@components/overlay/Modal/Modal";
import Portal from "@components/overlay/Portal/Portal";
import Backdrop from "@components/ux/Backdrop/Backdrop";
import Motion from "@components/ux/Motion/Motion";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import "./ValidatingAccessCode.scss";

const ValidatingAccessCode = () => {
  return (
    <Backdrop open>
      <Portal>
        <Stack justifyContent="center" alignItems="center">
          <Modal>
            <GradientBorder radius={12} withBoxShadow>
              <Stack className="validating" alignItems="center">
                <img
                  src="./images/validation-code-icon.svg"
                  alt="validation code icon"
                  className="validation-code-icon"
                />
                <h6 className="title white mt-6">Access Code verification</h6>
                <p className="p1 white-0 mt-2 mb-3">Please wait a moment</p>
                <Motion duration={4} spin style={{ width: "64px", height: "64px" }}>
                  <img src="/images/spinner.png" alt="spinner" className="spinner" />
                </Motion>
              </Stack>
            </GradientBorder>
          </Modal>
        </Stack>
      </Portal>
    </Backdrop>
  );
};

export default ValidatingAccessCode;
