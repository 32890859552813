import React from "react";
import { BigNumber, ethers } from "ethers";

import { ButtonVariants } from "@components/dataEntry/Buttons/Button/Button";
import GradientBorder from "@components/ux/GradientBorder/GradientBorder";
import { useTransactionProcess } from "@contexts/TransactionProcessContext";
import { useWalletData } from "@contexts/WalletDataContext";
import { useErrorModalContext } from "@contexts/ErrorModalContext";
import { ethereum } from "@services/ethereumInstance";
import { getRecordSessionStorage, SESSION_STORAGE_ACCESS_CODE } from "src/utils/sessionStorage/sessionStorage";
import WalletFeedback from "./WalletFeedback/WalletFeedback";
import { MIN_BNB_BALANCE } from "@data/saleData";
import { paperTrailClientInstance } from "@services/papertrailInstance";
import LoadingPresaleCardContent from "./LoadingPresaleCardContent/LoadingPresaleCardContent";
import NormalPresaleCardContent from "./NormalPresaleCardContent/NormalPresaleCardContent";
import { FetchStatus } from "@contexts/ContractDataContext";
import { useMetaMask } from "metamask-react";
import { useModalsState } from "@contexts/ModalsStateContext";
import { blockchainConfig } from "../../../../../config/config";
import "./PresaleCard.scss";

interface IPresaleCard {
  cardVariant: ButtonVariants;
  discountPercent: string;
  busdCost: number;
  currency: string;
  g2nPriceInBusd: number;
  g2nDiscountPriceInBusd: number;
  g2nAmountToGet: number;
  contractDataStatus: FetchStatus;
}

export type CardState = "loading" | "too-low-busd" | "too-low-bnb" | "contract-failed" | "wallet-failed" | "default";
const ACTION_REJECTED = "ACTION_REJECTED";

const PresaleCard = ({
  cardVariant,
  discountPercent,
  g2nPriceInBusd,
  g2nDiscountPriceInBusd,
  g2nAmountToGet,
  busdCost,
  currency,
  contractDataStatus
}: IPresaleCard) => {
  const [hover, setHover] = React.useState(cardVariant === "primary");

  const handleHover = () => setHover(true);
  const handleBlur = () => setHover(false);

  const { status: metamaskStatus, chainId } = useMetaMask();
  const metamaskConnected = metamaskStatus === "connected";
  const metamaskNotConnected = metamaskStatus === "notConnected";

  const { status: walletStatus, balances } = useWalletData();
  const { busdBalance, bnbBalance } = balances;
  const { showErrorModal } = useErrorModalContext();

  const { openConnectMetamask, openSwitchNetwork } = useModalsState();

  const loadingWallet = walletStatus === "loading";
  const walletFailed = walletStatus === "failed";
  const walletSuccess = walletStatus === "success";

  const contractLoading = contractDataStatus === "loading";
  const contractSuccess = contractDataStatus === "success";
  const contractFailed = contractDataStatus === "failed";

  const { getTransactionData, startProcessing, stopProcessing, updateAllowance } = useTransactionProcess();

  const cardState = React.useCallback((): CardState => {
    if (contractFailed) {
      return "contract-failed";
    }
    if (walletFailed) {
      return "wallet-failed";
    }
    if ((!metamaskConnected && contractLoading) || (metamaskConnected && (contractLoading || loadingWallet))) {
      return "loading";
    }
    if (busdBalance.lt(ethers.utils.parseEther(busdCost.toString())) && metamaskConnected) {
      return "too-low-busd";
    }
    if (bnbBalance.lt(ethers.utils.parseEther(MIN_BNB_BALANCE.toString())) && metamaskConnected) {
      return "too-low-bnb";
    }
    return "default";
  }, [
    bnbBalance,
    busdBalance,
    busdCost,
    contractFailed,
    contractLoading,
    loadingWallet,
    metamaskConnected,
    walletFailed
  ]);

  const finalCardState = cardState();

  const cardDisabled =
    finalCardState === "contract-failed" ||
    finalCardState === "too-low-bnb" ||
    finalCardState === "too-low-busd" ||
    finalCardState === "wallet-failed";

  const correctNetwork = chainId === blockchainConfig.BSC.chainId;

  const cardClickAction = () => {
    if (metamaskNotConnected) {
      openConnectMetamask();
      return;
    }
    if (chainId !== blockchainConfig.BSC.chainId) {
      openSwitchNetwork();
      return;
    }

    handleBuy();
  };

  const onCardHover = () => {
    if ((contractSuccess && walletSuccess) || !correctNetwork) {
      return handleHover;
    }
    if (metamaskConnected && cardDisabled) {
      return () => {};
    }

    return () => {};
  };

  const accessCode =
    getRecordSessionStorage(SESSION_STORAGE_ACCESS_CODE)! || "Someone intentionally removed access code from storage";

  const handleBuy = async () => {
    startProcessing();
    updateAllowance(busdCost, g2nAmountToGet);
    const busdValue = BigNumber.from(BigInt(busdCost) * BigInt(10) ** BigInt(18));

    const reqBody = (globalThis as any).loggerBody;
    try {
      paperTrailClientInstance.info(reqBody, `User started buy process for ${busdCost} BUSD`);
      await ethereum.handleBuyOrder(busdValue, accessCode, getTransactionData);
    } catch (e: any) {
      stopProcessing();
      if (e.code !== ACTION_REJECTED) {
        showErrorModal(e.toLocaleString());
      }
      paperTrailClientInstance.error(reqBody, `Error while buying for ${busdCost}: ${e}`);
    }
  };

  return (
    <div
      className={`presale-card ${cardVariant} ${finalCardState} ${cardDisabled && correctNetwork ? "disabled" : ""}`}
    >
      <div
        onMouseEnter={onCardHover()}
        onMouseLeave={handleBlur}
        className={`presale-card-inner ${cardDisabled && correctNetwork ? "disabled" : ""}`}
      >
        <GradientBorder radius={8} withBoxShadow={finalCardState === "default"} hover={hover} className={`full-size`}>
          {finalCardState === "loading" ? (
            <LoadingPresaleCardContent />
          ) : (
            <NormalPresaleCardContent
              cardVariant={cardVariant}
              discountPercent={discountPercent}
              g2nPriceInBusd={g2nPriceInBusd}
              g2nDiscountPriceInBusd={g2nDiscountPriceInBusd}
              g2nAmountToGet={g2nAmountToGet}
              handleBuy={cardClickAction}
              busdCost={busdCost}
              currency={currency}
            />
          )}
        </GradientBorder>
      </div>
      <WalletFeedback cardState={finalCardState} />
    </div>
  );
};

export default PresaleCard;
