import React from "react";
import VestingPopup from "../VestingPopup/VestingPopup";

const VestingHoverable = () => {
  const [hover, setHover] = React.useState(false);

  const handleHover = () => setHover(true);
  const handleUnhover = () => setHover(false);

  return (
    <div className="vesting hoverable">
      <p className="underline p2 white-2" onMouseOver={handleHover} onMouseLeave={handleUnhover}>
        Vesting Round
      </p>
      {hover && (
        <VestingPopup>
          <p className="p2 black bold">Starts from October 2023 to July 2024</p>
          <p className="p2 black">
            There will be 10 vesting rounds,
            <br /> each lasting 20 days.
          </p>
        </VestingPopup>
      )}
    </div>
  );
};

export default VestingHoverable;
