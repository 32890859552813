import React from "react";
import PresaleGuards from "./Guards/PresaleGuards";
import Presale from "./Presale";

const PresaleWithGuards = () => {
  return (
    <PresaleGuards>
      <Presale />
    </PresaleGuards>
  );
};

export default PresaleWithGuards;
