import { IChildren } from "@components/interfaces/IChildren";
import Layout1 from "@components/layoutElements/Layout/LayoutVariants/Layout1";
import NoLayout from "@components/layoutElements/Layout/LayoutVariants/NoLayout";
import { paths } from "@data/paths";
import AccessPageWithGuards from "@pages/AccessPage/AccessPageWithGuards";
import AdminPage from "@pages/AdminPage/AdminPage";
import DebugPage from "@pages/DebugPage/DebugPage";
import Development from "@pages/Development/Development";
import NotFound from "@pages/NotFound/NotFound";
import PresaleWithGuards from "@pages/Presale/PresaleWithGuards";

export type Guard = ({ children }: IChildren) => JSX.Element;

export interface Route {
  path: string;
  page: () => JSX.Element;
  layout: ({ children }: IChildren) => JSX.Element;
}

export const routes: Route[] = [
  {
    path: paths.accessPage,
    page: AccessPageWithGuards,
    layout: NoLayout
  },
  {
    path: paths.presale,
    page: PresaleWithGuards,
    layout: Layout1
  },
  {
    path: paths.debug,
    page: DebugPage,
    layout: NoLayout
  },
  {
    path: paths.development,
    page: Development,
    layout: NoLayout
  },
  {
    path: paths.admin,
    page: AdminPage,
    layout: NoLayout
  },
  {
    path: paths.notFound,
    page: NotFound,
    layout: NoLayout
  }
];
