import Group from "@components/arrangement/Group/Group";
import React from "react";
import "./Input.scss";

interface IInput {
  label?: string;
  value: string;
  onChange(value: string): void;
  error?: string;
  placeholder?: string;
  type?: "text" | "email" | "number" | "password" | "search" | "tel" | "url";
  darkBackground?: boolean;
  className?: string;
}

const Input = ({
  label = "",
  onChange,
  value,
  error = "",
  placeholder = "",
  type = "search",
  darkBackground = false,
  className = ""
}: IInput) => {
  const id = React.useId();
  const inputClass = `${error && "danger-border danger"} ${darkBackground && "dark-background"}`;

  return (
    <div className={`input ${className}`}>
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        className={inputClass}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
        placeholder={placeholder}
        type={type}
        autoComplete="off"
      />
      {error && (
        <Group colGap={4.5} alignItems="center" className="error">
          <img src="/images/error-x.svg" alt="error icon" />
          <p>{error}</p>
        </Group>
      )}
    </div>
  );
};

export default Input;
