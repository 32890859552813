import React from "react";

interface IContext {
  children: React.ReactNode;
}

const ErrorModalContext = React.createContext(null as any);

export const ErrorModalProvider = ({ children }: IContext) => {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const showErrorModal = (error: string) => setErrorMessage("Stack trace: " + error);

  const closeErrorModal = () => setErrorMessage(null);

  return (
    <ErrorModalContext.Provider
      value={{
        errorMessage,
        showErrorModal,
        closeErrorModal
      }}
    >
      {children}
    </ErrorModalContext.Provider>
  );
};

export const useErrorModalContext = () => React.useContext(ErrorModalContext);
