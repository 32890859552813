import React from "react";
import { IChildren } from "@components/interfaces/IChildren";
import { OrderHistoryProvider } from "@contexts/OrderHistoryContext";
import { TransactionProcessProvider } from "@contexts/TransactionProcessContext";
import { LoggerProvider } from "@logger/LoggerContext";
import { ErrorModalProvider } from "@contexts/ErrorModalContext";

const PresaleProviders = ({ children }: IChildren) => {
  return (
    <OrderHistoryProvider>
      <TransactionProcessProvider>
        <ErrorModalProvider>
          <LoggerProvider>{children}</LoggerProvider>
        </ErrorModalProvider>
      </TransactionProcessProvider>
    </OrderHistoryProvider>
  );
};

export default PresaleProviders;
